import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Report} from '@platform/models/report.model';
import {Concept} from '@platform/models/concept.model';
import {ConceptDataItem} from '@app/deliverables/factors/models/factors.model';
import {FactorsDisplay} from '@app/deliverables/factors/models/factors-display';
import {FactorsMapping} from '@app/deliverables/factors/models/factors-mapping';
import {ThresholdsMapping} from '@app/deliverables/factors/models/thresholds-mapping';
import {ColumnsViewService} from '@app/deliverables/factors/services/concepts-view.service';

@Component({
    selector: 'ns-benchmark-concept-factors',
    templateUrl: './benchmark-concept-factors.component.html',
    styleUrls: ['./benchmark-concept-factors.component.scss']
})
export class BenchmarkConceptFactorsComponent implements OnInit, OnChanges {

    @Input() report: Report;
    @Input() factorsDataForConcept: ConceptDataItem;
    @Input() concept: Concept;

    factors: Array<FactorsDisplay> = [];

    constructor(private columnsViewService: ColumnsViewService) {
    }

    ngOnInit(): void {
        this.initialize();
    }

    ngOnChanges() {
        this.initialize();
    }

    initialize() {
        const isAlcoholStudy = this.report.projectType && this.report.projectType.toLowerCase() === 'alcohol';
        const isCannabisStudy = this.report.projectType && this.report.projectType.toLowerCase() === 'cannabis';
        const factorOptions = FactorsMapping;
        const conceptData = this.factorsDataForConcept;
        const thresholds = Object.keys(ThresholdsMapping).reduce((acc, key) => {
            acc[ThresholdsMapping[key].id] = ThresholdsMapping[key];
            return acc;
        }, {});

        this.factors = Object.keys(factorOptions).reduce((acc, key) => {
            const factorValue = conceptData[factorOptions[key].id];
            if (factorValue !== undefined) {
                const matchingThreshold = thresholds[factorValue.toLowerCase()];
                if (matchingThreshold) {
                    const factor: FactorsDisplay = new FactorsDisplay();
                    if ((isAlcoholStudy || isCannabisStudy) && factorOptions[key].code === factorOptions.NEED_DESIRE.code) {
                        factor.code = factorOptions.DESIRE.code;
                    } else {
                        factor.code = factorOptions[key].code;
                    }
                    factor.tooltip = this.columnsViewService.getFactorTooltip(factorOptions[key].code, matchingThreshold);
                    factor.color = matchingThreshold.color;
                    acc.push(factor);
                }
            }
            return acc;
        }, []);
    }

}
