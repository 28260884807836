<div class="deliverable-container factors-content">
  <ng-container *ngIf="!scoreCardConcept">
    <!--Deliverable headers -->
    <div class="sticky-header-container header-filter-container" *ngIf="!scoreCardConcept">
      <div class="sticky-header-container">
        <ns-deliverables-header *ngIf="displayDeliverableHeader">
          <ns-kebab-menu>
            <ul class="kebab-menu-list">
              <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
            </ul>
          </ns-kebab-menu>
          <span *ngIf="isInternalUser" [ngClass]="{ 'disable-insight-btn': disableBtn }"
            class="insight-icon sif sif-insight" (click)="openInsightCreationForm()"></span>
        </ns-deliverables-header>
      </div>
    </div>
    <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableData" (close)="closeInsight()">
    </ns-deliverable-insight>
  </ng-container>


  <div id="deliverable-container">
    <div class="filters-sticky-container">
      <!--factors filters -->
      <ns-factors-filter [concept]="scoreCardConcept" [isConceptView]="isConceptView"></ns-factors-filter>
    </div>

    <!--Swipe table-->
<!--    <div *ngIf="!isConceptView" class="sticky-swipe-table-container">-->
<!--      <div class="swipe-container">-->
<!--        <ns-swipe-table class="pull-right" [isManaged]="false" [staticColumns]="staticColumns" [columnWidthList]="columnWidths" (change)="update($event)"-->
<!--                        (next)="nextPage()" (previous)="previousPage()"></ns-swipe-table>-->
<!--      </div>-->
<!--    </div>-->

    <div class="factors-for-success-table-container deliverable-insight-factors insight-html-root carousel-container">
      <ng-container *ngIf="filter">
        <ng-container [ngSwitch]="filter.deliverableViewType">
          <ns-concepts-view *ngSwitchDefault  (hasDeliverableData)="checkDeliverableDataPresence($event)"></ns-concepts-view>
          <ns-carousel-table *ngSwitchCase="'subgroup'" class="carousel-panel" [isManaged]="false" [staticColumns]="staticColumns" [columnWidthList]="columnWidths" (change)="update($event)"
                             (next)="nextPage()" (previous)="previousPage()"></ns-carousel-table>
          <ns-subgroup-means *ngSwitchCase="'subgroup'" (hasDeliverableData)="checkDeliverableDataPresence($event)"></ns-subgroup-means>
        </ng-container>
      </ng-container>
      <!-- Import Concepts Section -->
      <!-- <div class="import-section">
        <button *ngIf="!isImport" (click)="openImport()" mat-button class="import-button">+ Import Concepts</button>
        <div *ngIf="isImport" class="import-details">
          <div class="import-details-body">
            <div class="form">
              <input matInput placeholder="Please enter Project Name or Concept Name" class="import-search-bar">
              <button mat-button class="import-search-button"><span class="sif sif-search">search</span></button>
            </div>
            <div class="buttons">
              <button (click)="cancelImport()" mat-button class="cancel">Cancel</button>
              <button (click)="applyImport()" mat-button class="import">Import</button>
            </div>
          </div>
        </div>
      </div> -->

      <!--Factors - Footer legend Section-->
      <div class="legend" *ngIf="hasData">
        <ns-factors-legend class="legend-container"></ns-factors-legend>
      </div>

    </div>

  </div>
</div>
