<div class="deliverable-container reach-analysis-content">
  <ng-container *ngIf="!scoreCardConcept">
    <div class="sticky-header-container header-filter-container" [ngClass]="{'hide': isInsightEnable}">
        <ns-deliverable-nav-header [deliverableInfos]="deliverableInfos" (deliverableChanged)="onDeliverableChange($event)">
            <ns-kebab-menu class="kabab-menu">
                <ul class="kebab-menu-list">
                    <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
                </ul>
            </ns-kebab-menu>
            <span *ngIf="isInternalUser" [ngClass]="{'disable-insight-btn': disableBtn}" rel="tooltip" matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}" class="insight-icon sif sif-insight" (click)="openInsightCreationForm()"></span>
            <span saveUserView>
                <ns-save-user-view class="save-views"
                                   [(views)]="userViews"
                                   [filter]="filter"
                                   [reportId]="report.id"
                                   [deliverableType]="deliverableType"
                                   (viewsChange)="userViews=$event"
                                   (selectUserView)="selectUserView($event)"></ns-save-user-view>
            </span>
        </ns-deliverable-nav-header>
    </div>
  </ng-container>
     <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableData" (close)="closeInsight()"></ns-deliverable-insight>
    <div id="deliverable-container">
      <div class="filters-sticky-container" [ngClass]="{'hide': isInsightEnable}">
        <!--ReachAnalysis filter-->
        <ns-reach-anaylsis-filter [concept]="scoreCardConcept"></ns-reach-anaylsis-filter>
      </div>
      <div id="reach-analysis-content" class="reach-analysis-content insight-html-root" *ngIf="filter">
        <div class="questionList">
          <div class="sticky-dropdown deliverable-content">
            <div class="reach-question-dropdown-field">
              <mat-form-field appearance="fill" class="mySelectForm">
                <span class="dropdown-icon sif sif-chevron-s"></span>
                <mat-select class="combo-box question-text" [(ngModel)]="questionSelect" disableRipple (selectionChange)="selectQuestion($event.value)">
                  <mat-option *ngFor="let question of questionsList" [value]="question.id">
                    {{question.name + ": " + getViewLabel(question.id)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <p class="nsize">{{ 'shared.deliverables.reach.subgroup.placeholder' | translate : {segmentName:segmentName, country:countryName, completes:reachAnalysis.nSize  } }}</p>
        </div>
        <ng-container *ngIf="filter">
          <ng-container [ngSwitch]="compareView">
            <ns-summary-view [reachAnalysis]="reachAnalysis" [displaySpinner]="questionViewDisplaySpinner || filterChangeDisplaySpinner"
              *ngSwitchDefault>
            </ns-summary-view>
            <ns-detailed-view [reachAnalysis]="reachAnalysis" [displaySpinner]="questionViewDisplaySpinner || filterChangeDisplaySpinner" [report]="report"
              *ngSwitchCase="'detailed-view'">
            </ns-detailed-view>
            <ns-alternative-combination [reachAnalysis]="reachAnalysis" [displaySpinner]="questionViewDisplaySpinner || filterChangeDisplaySpinner"
              *ngSwitchCase="'alternative-combination'">
            </ns-alternative-combination>
          </ng-container>
        </ng-container>
      </div>
    </div>
  <mat-progress-spinner *ngIf="displayProgressSpinner" style="margin:0 auto;" color="secondary" diameter="100" mode="indeterminate" value="50"></mat-progress-spinner>
</div>

