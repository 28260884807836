import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, HostListener} from '@angular/core';
import {Report} from '@platform/models/report.model';
import {DeliverableConfiguration} from '@platform/models/deliverable-configuration.model';
import {Subscription} from 'rxjs';
import {DeliverableConfigurationService} from '@platform/services/deliverable-configuration.service';

@Component({
    selector: 'ns-correlations-configuration',
    templateUrl: './correlations-configuration.component.html',
    styleUrls: ['./correlations-configuration.component.scss']
})
export class CorrelationsConfigurationComponent implements OnInit, OnChanges {

    /**
     * Report object received from deliverable-configuration
     */
    @Input() report: Report;

    /**
     * Initial values for the correlations column headers.
     */
    @Input() dataList: DeliverableConfiguration;

    @Input() toggleDataTableAllValues: boolean;

    @Input() toggleQuadTableAllValues: boolean;

    @Output() closeFlyoutMenuEvent = new EventEmitter();

    @Output() moveToPreviousMenuEvent = new EventEmitter();

    /**
     * Subscription for deliverableInfo
     *
     * @type {Subscription}
     * @memberof DeliverablesComponent
     */
    subscriptions: Array<Subscription>;

    displayedColumns: string[] = ['correlationLabel', 'dataTable', 'quadMap'];

    metaInfoMap = [];


    constructor(
        private deliverableConfigurationService: DeliverableConfigurationService
    ) {
        this.subscriptions = [];
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setColumnInfo();
    }

    /**
     * Initialize the Correlations configuration component view.
     * @memberof DeliverableConfigurationComponent
     */
    ngOnInit(): void {
        this.setColumnInfo();
    }

    /**
     * Method to get the names and boolean values for the columnHeaders.
     */
    setColumnInfo(): void {
        if (this.dataList) {
            this.metaInfoMap = [...this.dataList.config.measures];
            this.metaInfoMap =  this.metaInfoMap.filter((m) => !m.isDisabled);
            this.toggleDataTableAllValues = this.metaInfoMap.find((item) => item.dataTable) ? true : false;
            this.toggleQuadTableAllValues = this.metaInfoMap.find((item) => item.quadMap) ? true : false;
        }
    }
    /**
     * Method to handle onClick.
     * @param column the columnHeader value the user is changing.
     * @param type
     */
    toggleView(column, type): void {
        const config = this.metaInfoMap.find(it => it.correlationLabel === column.correlationLabel);
        if (type === 'dataTable') {
            config.dataTable = !config.dataTable;
        } else {
            config.quadMap = !config.quadMap;
        }
        config.selected = config.dataTable || config.quadMap;
        this.toggleDataTableAllValues = this.metaInfoMap.find((item) => item.dataTable) ? true : false;
        this.toggleQuadTableAllValues = this.metaInfoMap.find((item) => item.quadMap) ? true : false;
    }

    setAllValues(type: string): void {
        if (type === 'dataTable') {
            this.metaInfoMap.map((item) => item.dataTable = item.selected = this.toggleDataTableAllValues);
        } else {
            this.metaInfoMap.map((item) => item.quadMap = item.selected = this.toggleQuadTableAllValues);
        }
    }

    onCloseButtonClick(): void {
        this.closeFlyoutMenuEvent.emit();
    }

    returnBack(): void {
        this.moveToPreviousMenuEvent.emit();
    }

    @HostListener('document:click', ['$event']) clickOut(event) {
        if (event.target.className.includes('mat-drawer-backdrop')) {
            event.stopPropagation();
            event.preventDefault();
            this.closeFlyoutMenuEvent.emit();
        }
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
        if (event.keyCode === 27) {
            this.closeFlyoutMenuEvent.emit();
        }
    }
}
