import {State} from '@platform/store/state/app.state';
import {AppConfigService} from '@app/core/config/app-config.service';
import {addReport, updateReport} from '@platform/store/actions/report.actions';
import {Report} from '@platform/models/report.model';
import {Injectable} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {Observable, of, Subject, zip} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {take, concatMap, switchMap} from 'rxjs/operators';
import {selectRouter} from '@platform/store/selectors/router.selectors';
import {selectReport} from '@platform/store/selectors/report.selectors';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {Deliverable} from '@platform/models/deliverable.model';

/**
 * Report service has operations to retrieve the current report.
 *
 * @example
 * constructor(private reportService: ReportService) { }
 *
 * @export
 * @class ReportService
 */
@Injectable({
    providedIn: 'root'
})
export class ReportService {

    /**
     * Subject that is used to trigger reloading of deliverable.
     * NOTE: used in conjunction with deliverable configuration update in flyout menu
     */
    readonly reloadDeliverable = new Subject();

    /**
     * List of product types for which importing benchmark in enabled
     *
     * @type {Array<String>}
     * @memberof ReportService
     */
    readonly PRODUCTS_WIH_BENCHMARK_ENABLED = ['QP'];

    /**
     * Observable that is used to track if reset deliverable button was triggered/clicked.
     *
     * @type {Subject<void>}
     * @memberof ReportService
     */
    readonly resetDeliverableCards$: Subject<void> = new Subject<void>();

    /**
     * Observable that is used to track if default card view exists.
     *
     * @type {Subject<boolean>}
     * @memberof ReportService
     */
    readonly defaultCardsViewExist$: Subject<boolean> = new Subject<boolean>();


    readonly correlationConfigChanged$: Subject<boolean> = new Subject<boolean>();

    /**
     * Creates an instance of ReportService.
     *
     * @constructor
     * @param {Store<State>} store The application state
     * @param {HttpClient} httpClient The HttpClient.
     * @memberof ReportService
     */
    constructor(
        private store: Store<State>,
        private httpClient: HttpClient,
        private cs: AppConfigService) {
    }

    /**
     * Returns the current report observable. The report id if retrieved from
     * the route and tries to fetch the report from store. If the report object
     * not available in the store then it will be fetched from the API
     *
     * @example
     * const report$ = reportService.get();
     *
     * @returns {Observable<Report>}
     * @memberof ReportService
     */
    get(): Observable<Report> {
        const routerState$ = this.store.pipe(select(selectRouter));
        let report$ = this.fetchReportFromStore();
        return zip(report$, routerState$).pipe(
            concatMap(result => {
                const urlReportId = result[1].params.reportId;
                if (!result[0] || result[0].id !== urlReportId) {
                    report$ = this.fetchReportFromAPI(urlReportId);
                }
                return report$;
            }),
            take(1)
        );
    }

    /**
     * Updates report object in the store.
     *
     * @param {Report} report The report object
     * @memberof ReportService
     */
    public update(report: Report): void {
        this.store.dispatch(updateReport({report}));
    }

    /**
     * Fetched report object from the API.
     *
     * @private
     * @param {string} reportId The report id.
     * @returns {Observable<Report>} The report observable
     * @memberof ReportService
     */
    public fetchReportFromAPI(reportId: string): Observable<Report> {
        const url = `${this.cs.config.reporting.url}/reports/${reportId}`;
        return this.httpClient.get<Report>(url).pipe(
            concatMap(result => {
                this.loadReportOnStore(result);
                return this.fetchReportFromStore();
            })
        );
    }

    /**
     * Loads a report into the store.
     *
     * @private
     * @param {Report} report The report object
     * @memberof ReportService
     */
    public loadReportOnStore(report: Report): void {
        this.store.dispatch(addReport({report}));
    }

    /**
     * Fetches a report object from the store.
     *
     * @private
     * @returns {Observable<Report>} The report observable.
     * @memberof ReportService
     */
    public fetchReportFromStore(): Observable<Report> {
        return this.store.pipe(select(selectReport));
    }

    /**
     * This will update the deliverables within reports data model in mongo
     * @param deliverables
     * @param reportId
     */
    public updateReportDeliverables(deliverables, reportId): Observable<any> {
        const url = `${this.cs.config.reporting.url}/reports/${reportId}/reportDeliverables`;
        const postBody = {};
        postBody['deliverables'] = deliverables;
        return this.httpClient.post(url, postBody);
    }

    /**
     * Update the Deliverables Array based on Deliverable Info's Array and send that update to Reports
     * @param report
     * @param infos
     * @param deliverablesInReport
     */
    public updateReportDeliverablePosition(reportId: string, infos: DeliverableInfo[]): Observable<Report> {
        const url = `${this.cs.config.reporting.url}/reports/${reportId}/deliverables/reorder`;
        const requestBody = {
            deliverablePositions: infos.map(it => {
                return {type: it.type, position: it.position};
            })
        };
        const updatedReport$: Observable<Report> = this.httpClient.put<Report>(url, requestBody);
        return updatedReport$.pipe(switchMap(updatedReport => {
            this.update(updatedReport);
            return of(updatedReport);
        }));
    }

    /**
     * Update the Deliverables Array based on Deliverable Info's Array and send that update to Reports
     * @param report
     * @param infos
     * @param deliverablesInReport
     */
    public updateReportDeliverablesOnStore(report: Report, infos: DeliverableInfo[], deliverablesInReport: Deliverable[]): void {
        const allReportDeliverables: Deliverable[] = [];
        // here we are adding the required data from deliverablesInfo array into deliverables array, example: Position
        deliverablesInReport.forEach(reportDeliverable => {
            const matchedDeliverableInfo = infos.find(deliverableInfo => deliverableInfo.type === reportDeliverable.type);
            if (matchedDeliverableInfo) {
                const updateDeliverables = {
                    type: reportDeliverable.type,
                    isComplete: reportDeliverable.isComplete,
                    views: reportDeliverable.views,
                    position: matchedDeliverableInfo.position,
                    showDeliverable:  reportDeliverable.showDeliverable + '' === 'false' ? reportDeliverable.showDeliverable : true
                };
                allReportDeliverables.push(updateDeliverables);
            }
        });
        // here we are updating the reports store with the updated deliverables array
        this.updateReportDeliverables(allReportDeliverables, report.id).subscribe(result => {
            this.update({
                ...report,
                deliverables: result
            });
        });
    }

    /**
     * Download the report in zip File
     * @param reportId
     */
    public downloadFile(reportId) {
        const url = `${this.cs.config.reporting.url}/reports/${reportId}/export`;
        this.httpClient.get(url, {responseType: 'blob'}).subscribe(response => {
            const blob = new Blob([response], {type: 'application/zip'});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `${reportId}-export.zip`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    }

    /**
     * Returns true of forecast deliverable exists in the given report
     * @param report
     */
    public forecastDeliverableExists(report: Report): boolean {
        return !!report.deliverables.find(it => it.type.startsWith('forecast'));
    }

    /**
     * Returns true if non forecast deliverable exists in the given report
     * @param report
     */
    public nonForecastDeliverableExists(report: Report): boolean {
        return !!report.deliverables.find(it => !it.type.startsWith('forecast'));
    }

    /**
     * Returns true if the import benchmark is enabled for a report
     * @param report
     */
    public canImportBenchmark(report: Report): boolean {
        return this.PRODUCTS_WIH_BENCHMARK_ENABLED.indexOf(report.productType) !== -1;
    }
}
