<div class="ns-flyout" [ngClass]="{'vertical': layoutTypeClass == 'vertical', 'horizontal': layoutTypeClass == 'horizontal'}">
    <mat-sidenav-container class="ns-flyout-container" [hasBackdrop]="hasBackdrop">
        <mat-sidenav disableClose [mode]="mode" [position]="position" [autoFocus]="false" #nsFlyout>
            <div class="ns-flyout-content">
                <ng-container *ngTemplateOutlet="sidenav"></ng-container>
            </div>
        </mat-sidenav>
        <mat-sidenav-content>
            <ng-container *ngTemplateOutlet="sidenavContent"></ng-container>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

