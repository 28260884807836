<ns-deliverable-configuration-header
        [headerText]="'platform.forecasting.variety.split.label' | translate"
        [showBackButton]=true
        (backBtnClickEvent)="returnBack()"
        (closeBtnClickEvent)="onCloseButtonClick()"></ns-deliverable-configuration-header>
<ns-deliverables-empty-state *ngIf="isDeliverableFromForecasting()==='false'" sifIcon="sif-analyze"
                             message="This section is available only for deliverables generated from forecasting">
</ns-deliverables-empty-state>

<mat-tab-group *ngIf="isDeliverableFromForecasting()==='true'" mat-align-tabs="center">
    <mat-tab>
        <ng-template mat-tab-label>
            <span class="sif sif-list-select cursor-pointer"></span>
        </ng-template>
        <div *ngIf="varietySplitDeliverableConfig?.chartConfig">
            <div class="config-section margin-top" [ngClass]="{'no-bottom-border':varietySplitDeliverableConfig.chartConfig.isExpanded}" (click)="valueChanged('chartConfigExpanded')">
                <div>
                    <button mat-icon-button>
                        <span [ngClass]="{'sif-chevron-s': varietySplitDeliverableConfig.chartConfig.isExpanded, 'sif-chevron-e': !varietySplitDeliverableConfig.chartConfig.isExpanded}" class="sif sif-gray cursor-pointer"></span>
                    </button>
                    <span class="deliverableView-text cursor-pointer">
                    {{'report.settings.deliverables.variety.split.config.chart.header' | translate}}
                </span>
                </div>
                <div>
                    <mat-slide-toggle
                        (change)="valueChanged('chartConfig')" [(ngModel)]="varietySplitDeliverableConfig.chartConfig.isVisible" (click)="$event.stopPropagation()">
                    </mat-slide-toggle>
                </div>
            </div>
            <div *ngIf="varietySplitDeliverableConfig.chartConfig.isExpanded" class="chart-section">
                <div class="radio-header">{{varietySplitDeliverableConfig.chartConfig.displayMessage}}</div>
                <div>
                    <mat-radio-button class="radio-item" [checked]="varietySplitDeliverableConfig.chartConfig.unitContribution.isSelected" color="primary"
                                      (change)="valueChanged('unitContribution')">{{varietySplitDeliverableConfig.chartConfig['unitContribution'].displayName}}</mat-radio-button>
                    <mat-radio-button class="radio-item" [checked]="varietySplitDeliverableConfig.chartConfig.revenueContribution.isSelected" color="primary"
                                      (change)="valueChanged('revenueContribution')">{{varietySplitDeliverableConfig.chartConfig['revenueContribution'].displayName}}</mat-radio-button>
                </div>
            </div>
        </div>
        <div *ngIf="varietySplitDeliverableConfig?.summaryConfig">
            <div class="config-section" [ngClass]="{'no-bottom-border':varietySplitDeliverableConfig.summaryConfig.isExpanded}" (click)="valueChanged('summaryConfigExpanded')">
                <div>
                    <button mat-icon-button>
                        <span [ngClass]="{'sif-chevron-s': varietySplitDeliverableConfig.summaryConfig.isExpanded, 'sif-chevron-e': !varietySplitDeliverableConfig.summaryConfig.isExpanded}" class="sif sif-chevron-s sif-gray cursor-pointer"></span>
                    </button>
                    <span class="deliverableView-text cursor-pointer">
                    {{'report.settings.deliverables.variety.split.config.summary.header' | translate}}
                </span>
                </div>
                <div>
                    <mat-slide-toggle
                        [(ngModel)]="varietySplitDeliverableConfig.summaryConfig.isVisible" (change)="valueChanged('summaryConfig')" (click)="$event.stopPropagation()">
                    </mat-slide-toggle>
                </div>
            </div>
            <div *ngIf="varietySplitDeliverableConfig.summaryConfig.isExpanded">
                <div class="list-section">
                    <div class="summary-list-header">{{'report.settings.deliverables.variety.split.config.rows.header' | translate}}</div>
                    <ns-deliverables-list class="deliverables-list" [editable]="true" [enableDragAndDrop]="true" [showVisibilityToggleIcon]="true" [showVisibilityToggleSlider]="false" [displayChildPageNavigation]="false" [dataList]="rowConfig"></ns-deliverables-list>
                </div>
                <div class="list-section">
                    <div class="summary-list-header">{{'report.settings.deliverables.variety.split.config.columns.header' | translate}}</div>
                    <ns-deliverables-list class="deliverables-list" [editable]="true" [enableDragAndDrop]="true" [showVisibilityToggleIcon]="true" [showVisibilityToggleSlider]="false" [displayChildPageNavigation]="false" [dataList]="columnConfig"></ns-deliverables-list>
                </div>
            </div>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span class="sif sif-agreement cursor-pointer"></span>
        </ng-template>

        <span class="section-header">
      {{'report.settings.deliverables.variety.split.config.estimate.header' | translate}}
    </span>
        <ns-deliverables-list class="deliverables-list" [dataList]="estimates" [editable]="true" [enableDragAndDrop]="false"
                              [showVisibilityToggleIcon]="true" [showVisibilityToggleSlider]="false"
                              [displayChildPageNavigation]="false" [hasExpandableData]="false">
        </ns-deliverables-list>
    </mat-tab>
</mat-tab-group>


