<mat-card class="concept-card" *ngIf="concept && benchmark">
    <div class="concept-card-header">
        <div class="editable-concept-name" *ngIf="benchmark">
            <h3 class="concept-name" *ngIf="editMode == false" [ngClass]="{'hovered':isHovered}"
                (click)="editMode = true;" (mouseover)="isHovered =true"
                (mouseout)="isHovered =false">{{ benchmark.displayName ? benchmark.displayName : concept.name}}</h3>
            <ns-input-with-counter *ngIf="editMode == true" class="edit-text"
                                   [(ngModel)]="concept.name" ngDefaultControl (focusout)="onBlurConceptName()"
                                   [maxLength]="maxTitleLength"
                                   [(value)]="benchmark.displayName ? benchmark.displayName : concept.name"
                                   [errorMsg]=" 'platform.benchmark.validate.error.text' | translate"
            ></ns-input-with-counter>
        </div>
        <span class="sample-group text-grey-2">{{subgroup?.name}}</span>
        <button *ngIf="benchmark" mat-icon-button class="trash-icon btn-delete-benchmark" rel="tooltip"
                    matTooltipPosition="above" matTooltip=" {{'platform.report.concepts.title.delete' | translate}}">
                <mat-icon (click)="deleteConfirmationDialog(concept, benchmark)">delete</mat-icon>
        </button>
    </div>
  <mat-card-content>
    <div class="card-content">
        <img [src]="conceptImageUrl" alt="Icon" class="concept-image-thumbnail padding-left-1-point-5 padding-right-1-point-5" (mouseenter) = "showFullSizeConcept()" (mouseleave)="closeFullSizeConcept()">
            <div *ngIf="!factorsDataForConcept && !fpMeasureDataForConcept" class="padding-top-1 padding-left-1-point-5 padding-right-1-point-5"> No Deliverable Data Found </div>
            <div *ngIf="!factorsDataForConcept && fpMeasureDataForConcept" class="padding-top-1 padding-left-1-point-5 padding-right-1-point-5"> No Factors Deliverable Data Found </div>
            <ns-benchmark-concept-factors
                class="padding-top-1 padding-left-1-point-5 padding-right-1-point-5"
                *ngIf="factorsDataForConcept"
                [factorsDataForConcept]="factorsDataForConcept"
                [concept]="concept"
                [report]="report">
            </ns-benchmark-concept-factors>
            <div *ngIf="fpMeasureDataForConcept" class="separator"></div>
            <div *ngIf="factorsDataForConcept && !fpMeasureDataForConcept" class="padding-top-1 padding-left-1-point-5 padding-right-1-point-5"> No Financial Potential Deliverable Data Found </div>
            <ns-benchmark-concept-financial-potential
                class="padding-top-1 padding-left-1-point-5 padding-right-1-point-5"
                *ngIf="showFinancialPotential"
                [measureDataForConcept]="fpMeasureDataForConcept"
                [showPurchaseIntent]="fpShowPurchaseIntent"
                [showFrequency]="fpShowFrequency"
                [showUnits]="fpShowUnits"
                [concept]="concept"
                [report]="report">
            </ns-benchmark-concept-financial-potential>
        <!--<ns-benchmark-concept-activation-profile
                class="padding-top-1 padding-left-1-point-5 padding-right-1-point-5"
                *ngIf="measureDataForConcept"
                [measureDataForConcept]="measureDataForConcept"
                [concept]="concept"
                [report]="report">
            </ns-benchmark-concept-activation-profile>-->
    </div>
  </mat-card-content>
</mat-card>

<ng-template #fullSizeConceptImageDialog>
  <img [src]="conceptImageUrl" alt="full size concept image" class="concept-image-full">
</ng-template>
