<div class="sticky-container facet-container">
  <div class="air-filters-bar clearfix">
    <div *ngIf="conceptNamesList" class="float-left">
      <ns-dropdown class="filter-item" [dropDownData]="conceptNamesList" (selectionChange)="onConceptSelectionChange($event.data)"></ns-dropdown>
      <ns-dropdown-menu rel="tooltip" matTooltipPosition="above" matTooltip=" {{'tooltip.filter' | translate: { viewComparison: 'Weighting Selected',
                        viewComparisonName: weightFilterTooltipLabel } }}"
                        class="filter-item" [label]="weightFilterLabel" [contentTitle]="'quick.screen.deliverables.sortingMatrix.filter.weighting.itemsTitle' | translate">
        <ns-dropdown-slider
          [deliverableLabel]="deliverableLabel"
          [defaultValue]="filter.weight"
          [maxValue]="'quick.screen.deliverables.sortingMatrix.filter.weighting.default.maxRange' | translate"
          [axisData]="axisData"
          (click)="$event.stopPropagation()"
          (resetWeight)="resetWeight($event)">
        </ns-dropdown-slider>
      </ns-dropdown-menu>
      <ns-dropdown class="filter-item" [dropDownData]="subgroupsList" (selectionChange)="selectSubgroup($event.data)"></ns-dropdown>
      <ns-dropdown class="filter-item id-filter" [dropDownData]="conceptIdsList" (selectionChange)="onShowIdsSelectionChange($event.data)"></ns-dropdown>
    </div>
  </div>
</div>
