import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Report} from "@platform/models/report.model";
import {Concept} from "@platform/models/concept.model";
import * as numeral from 'numeral';
import {Measures} from "@app/deliverables/financial-potential/models/financial-potential.model";
import {FinancialPotentialService} from "@app/deliverables/financial-potential/services/financial-potential.service";

@Component({
  selector: 'ns-benchmark-concept-financial-potential',
  templateUrl: './benchmark-concept-financial-potential.component.html',
  styleUrls: ['./benchmark-concept-financial-potential.component.scss']
})
export class BenchmarkConceptFinancialPotentialComponent implements OnInit, OnChanges {

  @Input() report: Report;
  @Input() measureDataForConcept: Measures;
  @Input() concept: Concept;
  @Input() showPurchaseIntent: boolean;
  @Input() showUnits: boolean;
  @Input() showFrequency: boolean;

  piTop2: string;
  piQuintileRank: string;
  trialUnitsAmongFavs: string;
  frequencyAmongFavs: string;
  trialUnitsRankScore: string;
  frequencyRankScore: string;

  constructor(private financialPotentialService: FinancialPotentialService) {
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnChanges() {
    this.initialize();
  }

  initialize() {
    this.piQuintileRank = this.financialPotentialService.getQuintileRank(this.measureDataForConcept.purchaseIntentData.rankScore);
    this.piTop2 = numeral(this.measureDataForConcept.purchaseIntentData.definitelyWouldBuy + this.measureDataForConcept.purchaseIntentData.probablyWouldBuy).format('0.0');
    this.trialUnitsAmongFavs = numeral(this.measureDataForConcept.trialUnitsData.trialUnitsAmongFavs).format('0.0');
    this.frequencyAmongFavs = numeral(this.measureDataForConcept.frequencyData.frequencyAmongFavs).format('0.0');
    this.trialUnitsRankScore = this.financialPotentialService.getQuintileRank(this.measureDataForConcept.trialUnitsData.rankScore);
    this.frequencyRankScore = this.financialPotentialService.getQuintileRank(this.measureDataForConcept.frequencyData.rankScore);
  }
}
