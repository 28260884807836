import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Report} from '@platform/models/report.model';
import {Concept} from '@platform/models/concept.model';
import {Organization} from '@platform/models/organization.model';
import {Benchmark} from '@platform/models/benchmark.model';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {ActivationProfileDeliverableView} from '@app/deliverables/activation-profile/models/activationProfile.model';
import {FactorsDeliverableView} from '@app/deliverables/factors/models/factors.model';
import {SubgroupService} from '@platform/services/subgroup.service';
import {combineLatest, Observable, Subscription, zip} from 'rxjs';
import {Subgroup} from '@platform/models/subgroup.model';
import {ConceptService} from '@platform/services/concept.service';
import {FinancialPotentialDeliverableView} from '@app/deliverables/financial-potential/models/financial-potential.model';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
import {TranslateService} from '@ngx-translate/core';
import {OrganizationService} from '@platform/services/organization.service';

/**
 * Project/report data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface ReportNode {
    name: string;
    report?: Report;
    concept?: Concept;
    subgroup?: Subgroup;
    selected?: boolean;
    children?: ReportNode[];
}

/** Flat node with expandable and level information */
interface ReportFlatNode {
    expandable: boolean;
    name: string;
    level: number;
    concept: Concept;
    report: Report;
    subgroup: Subgroup;
    selected: boolean;
}

@Component({
    selector: 'ns-add-benchmark-concepts',
    templateUrl: './add-benchmark-concepts.component.html',
    styleUrls: ['./add-benchmark-concepts.component.scss']
})
export class AddBenchmarkConceptsComponent implements OnInit, OnDestroy, OnChanges {

    @Input() report: Report;
    @Input() isExpanded: boolean;
    @Input() preExistingBenchmarks: Array<Benchmark>;
    @Input() importedConceptIds: [];

    /**
     * Event emitted that emits when concepts are selected/unselected.
     * Emitted data is of format {selected:[List of concepts that are selected], unselected:[List of concepts that are not selected]}
     * */
    @Output() conceptsToggled: EventEmitter<Record<string, Array<any>>> = new EventEmitter();
    @Output() reportToggled: EventEmitter<any> = new EventEmitter();

    indeterminate: boolean = false;
    checked: boolean = false;
    activationProfileDeliverableView: ActivationProfileDeliverableView = null;
    factorsDeliverableView: FactorsDeliverableView = null;
    financialPotentialDeliverableView: FinancialPotentialDeliverableView = null;
    subgroups: Array<Subgroup>;
    dbSubgroup: Subgroup;
    subscriptions: Subscription;
    concepts: Concept[];
    reportCreatedDate: string;
    reportOrg: Organization;

    private _transformer = (node: ReportNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level,
            report: node.report,
            concept: node.concept,
            subgroup: node.subgroup,
            selected: node.selected
        };
    };

    treeControl = new FlatTreeControl<ReportFlatNode>(
        node => node.level,
        node => node.expandable,
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        node => node.level,
        node => node.expandable,
        node => node.children,
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    constructor(private deliverableViewService: DeliverableViewService,
                private productDeliverableViewService: ProductDeliverableViewService,
                private organizationService: OrganizationService,
                private subgroupService: SubgroupService,
                private conceptService: ConceptService,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.subscriptions = new Subscription();
        this.initialize();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges() {
        if (this.isExpanded === false) {
            this.treeControl.collapseAll();
        }
    }

    initialize(): void {
        if (!!this.report) {
            const concepts$ = this.conceptService.fetchFromAPI(this.report.id);
            const subgroups$ = this.subgroupService.fetchFromAPI(this.report.id);
            const organization$ = this.organizationService.getById(this.report.orgId);
            this.subscriptions = zip(concepts$, subgroups$, organization$).subscribe(data => {
                // capture the first databasable subgroup data
                this.subgroups = data[1];
                this.dbSubgroup = this.subgroups?.find(it => it.isDatabaseable);
                // capture the concept data
                this.concepts = data[0];
                // do not add reports to the tree if concept data is null
                if (this.concepts.length > 0) {
                    // create concept data for the report search tree
                    const reportConcepts: ReportNode[] = this.concepts.map((concept) => {
                        return {
                            name: concept.name,
                            concept: concept,
                            subgroup: this.dbSubgroup,
                            report: this.report
                        };
                    });
                    // create the tree data for the report search container
                    const treeData: ReportNode[] = [
                        {
                            name: this.report.productName,
                            report: this.report,
                            children: reportConcepts
                        }
                    ];
                    this.dataSource.data = treeData;
                }
                // capture the other details of the report to show on the search container
                this.reportOrg = data[2];
                if (this.report.dateCreated) {
                    const reportCreatedDate = new Date(this.report.dateCreated);
                    this.reportCreatedDate = reportCreatedDate?.toLocaleString('default', { month: 'long' }) + ' ' + reportCreatedDate?.getFullYear();
                }
                this.setSelectAllCheckboxState();
            });
        }
    }

    hasChild = (_: number, node: ReportFlatNode) => node.expandable;

    toggleSelectAll(): void {
        const conceptNodes = this.treeControl.dataNodes?.filter((it) => it.level === 1);
        if (conceptNodes) {
            let allImported = conceptNodes.filter((it) =>this.isImported(this.importedConceptIds,it.concept.exerciseConceptId));
            let allSelected = conceptNodes.filter((it) => it.selected && !this.isImported(this.importedConceptIds,it.concept.exerciseConceptId));
            let allSelection = [...allImported, ...allSelected];
            let allNodesSelected = !(allSelection.length === conceptNodes.length);
            conceptNodes.forEach(it => it.selected = it.selected = allNodesSelected && !this.isImported(this.importedConceptIds,it.concept.exerciseConceptId));
            this.setSelectAllCheckboxState();
            this.outputConceptsToggled();
        }
    }

    toggleConcept(concept: Concept): void {
        const conceptNodes = this.treeControl.dataNodes?.filter(it => it.level === 1);
        if (conceptNodes) {
            let matchedConcept = conceptNodes.find(it => it.concept === concept);
            if (matchedConcept) {
                matchedConcept.selected = !matchedConcept.selected;
            }
            this.setSelectAllCheckboxState();
            this.outputConceptsToggled();
        }
    }

    isImported(listOfImported, reportId){
        return listOfImported.indexOf(reportId) !== -1;
    }

    setSelectAllCheckboxState(){
        const conceptNodes = this.treeControl.dataNodes?.filter((it) => it.level === 1);
        if (conceptNodes) {
            let allImported = conceptNodes.filter((it) =>this.isImported(this.importedConceptIds,it.concept.exerciseConceptId));
            let allSelected = conceptNodes.filter((it) => it.selected && !this.isImported(this.importedConceptIds,it.concept.exerciseConceptId));
            let allSelection = [...allImported, ...allSelected];

            if (allSelection.length > 0 && allSelection.length < conceptNodes.length) {
                this.indeterminate = true;
                this.checked = false;
            } else if (allSelection.length === conceptNodes.length) {
                this.indeterminate = false;
                this.checked = true;
            } else if (allSelection.length <= 0) {
                this.indeterminate = false;
                this.checked = false;
            }
        }
    }

    outputConceptsToggled(): void {
        const conceptNodes = this.treeControl.dataNodes?.filter(it => it.level === 1);
        if (conceptNodes) {
            let selectedConceptNodes = conceptNodes.filter(it => it.selected);
            let unSelectedConceptNodes = conceptNodes.filter(it => !it.selected);
            this.conceptsToggled.emit({
                selected: selectedConceptNodes,
                unselected: unSelectedConceptNodes
            });
        }
    }

    toggleExpand(dataNode: ReportFlatNode): void {
        if (this.treeControl.isExpanded(dataNode)) {
            this.treeControl.collapseAll();
        } else {
            this.treeControl.expandAll();
            // AFTER EXPANDING LOAD ALL THE DELIVERABLE DATA
            this.addDeliverableData(this.report.id);
            this.reportToggled.emit({
                    dataNode
            });
        }

    }

    /**
     * Add Deliverable Data
     */
    addDeliverableData(reportId) {
        const deliverableViews$ = this.deliverableViewService.fetchAllWithReportId(reportId);
        deliverableViews$.subscribe(result => {
            // Financial Potential Pull
            const financialPotentialDeliverableViews =  result.filter(it => it.type === 'financial.potential');
            const financialPotentialProductId = financialPotentialDeliverableViews[0]?.productViewId;
            // Factors Pull
            const factorsDeliverableViews = result.filter(it => it.type === 'factors');
            const factorsProductId = factorsDeliverableViews.find(it => it.viewName === 'concept')?.productViewId;
            // zip the 3 deliverables and capture data
            const financialPotentialViews$: Observable<FinancialPotentialDeliverableView> = this.productDeliverableViewService.get<FinancialPotentialDeliverableView>(reportId, financialPotentialProductId);
            const factorsViews$: Observable<FactorsDeliverableView> = this.productDeliverableViewService.get<FactorsDeliverableView>(reportId, factorsProductId);
            this.subscriptions = combineLatest([financialPotentialViews$, factorsViews$]).subscribe(data => {
                this.financialPotentialDeliverableView = data[0];
                this.factorsDeliverableView = data[1];
            });
        });
    }
}
