<ns-deliverable-configuration-header
        [headerText]="'platform.forecasting.marketing.summary.label' | translate"
        [showBackButton]=true
        (backBtnClickEvent)="returnBack()"
        (closeBtnClickEvent)="onCloseButtonClick()"></ns-deliverable-configuration-header>
<ns-deliverables-empty-state *ngIf="isDeliverableFromForecasting()=='false'" sifIcon="sif-analyze"
  message="This section is available only for deliverables generated from forecasting">
</ns-deliverables-empty-state>

<mat-tab-group *ngIf="isDeliverableFromForecasting()=='true'" mat-align-tabs="center">
  <mat-tab>
    <ng-template mat-tab-label>
      <span class="sif sif-list-select cursor-pointer"></span>
    </ng-template>
    <div class="general-div">
      <span class="section-header">{{'report.settings.deliverables.marketing.plan.config.table.informational' |
        translate |
        uppercase}}</span>
      <ns-deliverables-list class="deliverables-list" [dataList]="headerTableData" [editable]="true"
        [enableDragAndDrop]="true" [showVisibilityToggleIcon]="true" [showVisibilityToggleSlider]="false"
        [displayChildPageNavigation]="false"></ns-deliverables-list>
    </div>
    <div class="table-data-div">
      <span class="section-header">{{'report.settings.deliverables.marketing.plan.config.table.metrics' | translate |
        uppercase}}</span>
      <ns-deliverables-list class="deliverables-list" [dataList]="tableData" [editable]="true"
        [enableDragAndDrop]="true" [showVisibilityToggleIcon]="true" [showVisibilityToggleSlider]="false"
        [displayChildPageNavigation]="false" [currencyType]="currencyValue"
        [expandedRowsStoreCollection]="expandedRowsStoreCollection"></ns-deliverables-list>
    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span class="sif sif-agreement cursor-pointer"></span>
    </ng-template>

    <span class="section-header">
      {{'report.settings.deliverables.marketing.plan.config.estimate.header' | translate}}
    </span>
    <ns-deliverables-list class="deliverables-list" [dataList]="dataList" [editable]="true" [enableDragAndDrop]="false"
      [showVisibilityToggleIcon]="true" [showVisibilityToggleSlider]="false" [displayChildPageNavigation]="false"
      [hasExpandableData]="true" [expandedRowsStoreCollection]="estimateExpandedRowsStoreCollection">
      <ng-template #templateRef let-item>
        <div class="estimate-custom-content-container">
          <form [formGroup]="getFormGroup(item)">
            <ng-container *ngFor="let assumption of assumptionMapData">
              <div class="assumption-section">
                <span class="assumption-section-header">
                  {{assumption.label | translate }}
                </span>
                <div class="combo-div-box">
                  <mat-icon class="close-icon" (click)="clearData(assumption.name,item)">close</mat-icon>
                  <mat-icon class="down-arrow" (click)="
                  $event.stopPropagation();
                  trigger.panelOpen ? trigger.closePanel() : trigger.openPanel();
                  assumptionAutocompleteOpened(assumption.name,item,trigger)
                ">keyboard_arrow_down</mat-icon>
                  <input class="combo-box" type="text" [disabled]="isExcluded || isLocked" matInput
                    [formControlName]="assumption.name" matAutocompletePosition="below" [matAutocomplete]="auto"
                    #trigger="matAutocompleteTrigger" (change)="assumptionSelected($event,assumption.name,item)" />
                  <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)="assumptionSelected($event,assumption.name,item)">
                    <mat-option *ngFor="let option of getfilteredAssumptionOptions(assumption.name,item)"
                      [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>
            </ng-container>
          </form>
        </div>
      </ng-template>

    </ns-deliverables-list>
  </mat-tab>
</mat-tab-group>