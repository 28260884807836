<div class="table-wrapper factors-table-container" *ngIf="hasData; else noData">
  <mat-table [dataSource]="dataSource" class="factors-table mat-elevation-z8">
    <ng-container *ngFor="let colHeader of colHeaders;let i = index;trackBy: trackItem"
      matColumnDef="{{ colHeader.name }}">
      <ng-container *ngIf="i === 0">
        <mat-header-cell class="first-column statement-header" *matHeaderCellDef></mat-header-cell>
        <mat-cell class="first-column concept-name" *matCellDef="let element">
            <div class="concept-info">
                <h3>{{element.concept.name}} <sup>{{element.concept.suffix}}</sup></h3>
                <span class="imported-concept-report-name"
                      *ngIf="element.concept?.isImported">({{element.concept?.reportName}})</span>
            </div>
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="i !== 0">
        <mat-header-cell class="statement-header" *matHeaderCellDef>
          <div class="statement-columns">
            <div class="threshold-head" [ngClass]="colHeader.color">{{ colHeader.name }}</div>
          </div>
        </mat-header-cell>
        <mat-cell class="values" [ngClass]="colHeader.color" *matCellDef="let element">
          <div class="threshold-value">
            <ng-container *ngFor="let val of element[colHeader.name]">
              <div [ngClass]="val.color">
                  <span *ngIf="val.code !== '--'" class="block first-block" nsFactorsTooltipDirective [conceptsTooltip]="val" (click)="trackFactor()">
                    {{val.code}}
                  </span>
                <span *ngIf="val.code == '--'" class="block" style="color:black;background: none;">{{val.code}}</span>
              </div>
            </ng-container>
          </div>
        </mat-cell>
      </ng-container>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>

<ng-template #noData>
  <div class="no-data">
      <span class="data sif sif-no-results"></span>
      <h3>There are no Results</h3>
      <p>Try refining the filters to populate data.</p>
  </div>
</ng-template>