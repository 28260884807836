import {Component, Input, OnInit, EventEmitter, Output, HostListener} from '@angular/core';
import { DeliverablesListItem } from '@products/shared/deliverables-list/deliverables-list-item.data.model';
import { DeliverableConfigurationService } from '@platform/services/deliverable-configuration.service';
import { Subscription } from 'rxjs';
import { Report } from '@platform/models/report.model';
import { DeliverableInfo } from '@platform/models/deliverable-info.model';
import {
    CHARTCONFIG, SUMMARYCONFIG
} from '@platform/report/report-settings/deliverable-configuration/variety-split-deliverable-configuration/variety-split-deliverable-configuration-model';
import { DeliverableViewMatTreeService } from '@platform/services/deliverable-view-mat-tree.service';
import { DeliverableConfiguration } from '@platform/models/deliverable-configuration.model';

@Component({
    selector: 'ns-variety-split-deliverable-configuration',
    templateUrl: './variety-split-deliverable-configuration.component.html',
    styleUrls: ['./variety-split-deliverable-configuration.component.scss']
})
export class VarietySplitDeliverableConfigurationComponent implements OnInit {
    @Input() rowConfig: DeliverablesListItem[];
    @Input() columnConfig: DeliverablesListItem[];
    @Input() estimates: DeliverablesListItem[];
    @Output() configUpdated: EventEmitter<any>;
    @Input() public report: Report;
    @Input() varietySplitDeliverableConfig: any;
    @Input() deliverablesConfig: any;
    @Output() closeFlyoutMenuEvent = new EventEmitter();
    @Output() moveToPreviousMenuEvent = new EventEmitter();

    chartConfig: any;
    summaryConfig: any;
    estimateConfig: any;
    subscriptions: Subscription;

    constructor(private deliverableConfigurationService: DeliverableConfigurationService) {
        this.configUpdated = new EventEmitter<any>();
    }

    ngOnInit() {
        let deliverableConfigurationsInReport: any[];
        if (!(this.deliverablesConfig && this.deliverablesConfig.length > 0)) {
            this.deliverablesConfig = [];
        }
        deliverableConfigurationsInReport = JSON.parse(JSON.stringify(this.deliverablesConfig));
        if (!deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FORECAST_VARIETY_SPLIT)) {
            deliverableConfigurationsInReport.push({
                reportId: this.report.id,
                name: this.deliverableConfigurationService.FORECAST_VARIETY_SPLIT,
                config: {
                    'chartConfig': CHARTCONFIG,
                    'summaryConfig': SUMMARYCONFIG,
                    'estimates': []
                }
            });
            this.deliverableConfigurationService.isDeliverablesConfigReGenerated = false;
        }
        this.deliverableConfigurationService.updateConfigForForecastEstimate(deliverableConfigurationsInReport, this.report.id);
    }
    valueChanged(value: string) {
        if (this.varietySplitDeliverableConfig.chartConfig.isVisible === false && this.varietySplitDeliverableConfig.summaryConfig.isVisible === false) {
            (value === 'chartConfig') ? this.varietySplitDeliverableConfig.summaryConfig.isVisible = true : this.varietySplitDeliverableConfig.chartConfig.isVisible = true;
        }
        if (value === 'unitContribution' || value === 'revenueContribution') {
            this.varietySplitDeliverableConfig.chartConfig.unitContribution.isSelected = !this.varietySplitDeliverableConfig.chartConfig.unitContribution.isSelected;
            this.varietySplitDeliverableConfig.chartConfig.revenueContribution.isSelected = !this.varietySplitDeliverableConfig.chartConfig.revenueContribution.isSelected;
        }
        if (value === 'chartConfigExpanded' || value === 'summaryConfigExpanded') {
            (value === 'chartConfigExpanded') ? this.varietySplitDeliverableConfig.chartConfig.isExpanded = !this.varietySplitDeliverableConfig.chartConfig.isExpanded :
                this.varietySplitDeliverableConfig.summaryConfig.isExpanded = !this.varietySplitDeliverableConfig.summaryConfig.isExpanded;
        }
        this.configUpdated.emit(this.varietySplitDeliverableConfig);
    }

    isDeliverableFromForecasting() {
        return this.deliverableConfigurationService.isVarietySplitDeliverableFromForecasting?.toString();
    }

    onCloseButtonClick(): void {
        this.closeFlyoutMenuEvent.emit();
    }

    returnBack(): void {
        this.moveToPreviousMenuEvent.emit();
    }

    @HostListener('document:click', ['$event']) clickOut(event) {
        if (event.target.className.includes('mat-drawer-backdrop')) {
            event.stopPropagation();
            event.preventDefault();
            this.closeFlyoutMenuEvent.emit();
        }
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
        if (event.keyCode === 27) {
            this.closeFlyoutMenuEvent.emit();
        }
    }
}
