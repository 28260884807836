import { Injectable } from '@angular/core';
import { StringHelper } from '@platform/services/string-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterItem, InteractionsFilter } from '../models/filter.model';
import { Competitor, ConceptDataItem, InteractionsDeliverableView } from '../models/interactions.model';
import { BarChart, BarChartData } from '@products/shared/chart-horizontal-bar/bar-chart.model';
import { defaultBarChartOptions } from '../models/default-interactions-barchart-options';
import { defaultInteractionsColors } from '../models/default-interactions-colors';
import { Metric } from '../models/metric.enum';


/**
 * `InteractionsChartDataService` has operations for data tranformation for
 * Interactions chart.
 *
 * @export
 * @class InteractionsChartDataService
 */
@Injectable({
    providedIn: 'root'
})
export class InteractionsChartDataService {

    /**
     * Creates an instance of InteractionsChartDataService.
     *
     * @constructor
     * @param stringHelper
     * @param {TranslateService} translate
     * @memberof InteractionsChartDataService
     */
    constructor(
        private stringHelper: StringHelper,
        private translate: TranslateService
    ) { }

    /**
     * Returns a tuple of colheaders and chart data for angular material table.
     *
     * @param {InteractionsFilter} filter
     * @param {InteractionsDeliverableView} interactions
     * @returns {[Array<any>, Array<any>]} - The tuple of column headers and chart data.
     * @memberof InteractionsChartDataService
     */
    public getChartData(filter: InteractionsFilter, interactions: InteractionsDeliverableView): [Array<any>, Array<any>] {
        const data = [];
        const nColHeaders = [];
        this.checkSelectedConcepts(data, nColHeaders, interactions);
        const selectedConcepts = this.selectedFilterItems(filter.concepts);
        selectedConcepts.sort(function (a, b) {
            return a.position - b.position;
        });
        const conceptsMap = this.getFilterItemMap(selectedConcepts);
        let rowData, cellData, cellDataKey, colHeader;
        const colHeaders: Array<any> = [...selectedConcepts];
        interactions.subgroups.forEach((subgroup) => {
            const maximumFairShareIndex = this.findMaxIndexValue(subgroup.competitors, conceptsMap);
            subgroup.competitors.forEach((competitorDataItem, index) => {
                rowData = {};
                rowData.competitor = filter.concepts.filter(c => c.id === competitorDataItem.conceptId).map(c => c.name);
                const conceptDataList = competitorDataItem.shareIndices.filter(conceptDataItem => !!conceptsMap[conceptDataItem.conceptId]);
                conceptDataList.forEach((conceptDataItem) => {
                    cellData = {};
                    cellDataKey = conceptsMap[conceptDataItem.conceptId];
                    cellData.barChart = this.getBarChart(filter, conceptDataItem, competitorDataItem, maximumFairShareIndex);
                    cellData.fairShareIndex = conceptDataItem.fairShareIndex;
                    rowData[cellDataKey?.name] = cellData;
                    if (cellDataKey && index === 0 && !nColHeaders.find(col => col.id === cellDataKey.id)) {
                        colHeader = colHeaders.find(ch => ch.id === cellDataKey.id);
                        const newValue = Object.assign({}, colHeader);
                        nColHeaders.push(newValue);
                    }
                });
                data.push(rowData);
            });
        });
        nColHeaders.sort(function (a, b) {
            return a.position - b.position;
        });
        nColHeaders.unshift({ name: 'competitor' });
        return [nColHeaders, data];
    }

    /**
     * Returns empty table data if no concept is selected.
     *
     * @param {Array<any>} data
     * @param {Array<any>} nColHeaders
     * @param {InteractionsDeliverableView} interactions
     * @returns {[Array<any>, Array<any>]} - The tuple of column headers and chart data.
     * @memberof InteractionsChartDataService
     */
    checkSelectedConcepts(data: Array<any>, nColHeaders: Array<any>, interactions: InteractionsDeliverableView): [Array<any>, Array<any>] {
        if (interactions.subgroups.every(subgroup => subgroup.competitors.length === 0)) {
            return [nColHeaders, data];
        }
        if (interactions.subgroups.every(subgroup => {
            subgroup.competitors.every(competitor => competitor.shareIndices.length === 0);
        })) {
            return [nColHeaders, data];
        }
    }

    /**
     * Returns the bar chart object for the competitor data item.
     *
     * @private
     * @param {InteractionsFilter} filter
     * @param conceptDataItem
     * @param {Competitor} competitorDataItem
     * @param maximumFairShare Maximum fair share across the concepts
     * @returns {BarChart}
     * @memberof InteractionsChartDataService
     */
    private getBarChart(filter: InteractionsFilter, conceptDataItem: ConceptDataItem, competitorDataItem: Competitor, maximumFairShare: number): BarChart {
        const options: any = JSON.parse(JSON.stringify(defaultBarChartOptions));
        options.bar.domain.max = maximumFairShare;
        options.bar.tooltip = true;
        let barChart: BarChart;
        barChart = {
            colors: Object.assign({}, defaultInteractionsColors),
            options: options,
            series: [this.filterDataPoints(filter, conceptDataItem, competitorDataItem)]
        };
        return barChart;
    }

    /**
     * This will find the Maximum Value from array of data items and round it up to next big 10s digit
     * Example: 10,20,30,40 etc
     * @private
     * @param items
     * @param conceptsMap
     */
    private findMaxIndexValue(items: Array<Competitor>, conceptsMap: { [key: number]: FilterItem }): number {
        let maximumFairShare = 0;
        items.forEach((item: Competitor) => {
            item.shareIndices.forEach((conceptDataItem: ConceptDataItem) => {
               if (!!conceptsMap[conceptDataItem.conceptId]) {
                 maximumFairShare = conceptDataItem.fairShareIndex > maximumFairShare ? conceptDataItem.fairShareIndex : maximumFairShare;
               }
            });
        });
        return Math.ceil(maximumFairShare / 10.0) * 10;
    }

    /**
     * Filters a competitor data item for data points.
     *
     * @private
     * @param {InteractionsFilter} filter
     * @param {Restage} restageDataItem
     * @param {Competitor} competitorDataItem
     * @returns {BarChartData}
     * @memberof InteractionsChartDataService
     */
    private filterDataPoints(filter: InteractionsFilter, conceptDataItem: ConceptDataItem, competitorDataItem: Competitor): BarChartData {
        const result = {};
        const id = `${conceptDataItem.conceptId}-${competitorDataItem.conceptId}`;
        this.addDataPoint(Metric.DEFAULT, id, conceptDataItem.fairShareIndex, result, filter);
        return result;
    }

    /**
     * Adds a data point.
     *
     * @private
     * @param {string} key
     * @param {string} id
     * @param {number} value
     * @param {*} data
     * @param {InteractionsFilter} filter
     * @memberof InteractionsChartDataService
     */
    private addDataPoint(key: string, id: string, value: number, data: any, filter: InteractionsFilter): void {
        value = value ? value : 0;
        data[key] = {
            id: `${key}-${id}`,
            value: value,
            tooltip: this.getTooltip(value)
        };
    }

     /**
   * Returns tooltip text based on the filter and value.
   *
   * @param {string} key the metric key
   * @param {number} value the value
   * @param {InteractionsFilter} filter the filter
   * @private
   * @method
   */

    private getTooltip(value: number) {
        let tooltip: string;
        switch (true) {
            case value < 100:
                tooltip = this.translate.instant('restage.deliverable.interactions.chart.tooltip.lessthan.100');
                break;
            case value === 100:
                tooltip = this.translate.instant('restage.deliverable.interactions.chart.tooltip.equals.100');
                break;
            case value > 100:
                tooltip = this.translate.instant('restage.deliverable.interactions.chart.tooltip.greaterthan.100');
                break;
            default:
                tooltip = 'none';
        }
        return tooltip;
    }

    /**
     * Returns map of filter items.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {{[key: number]: FilterItem}}
     * @memberof InteractionsChartDataService
     */
    private getFilterItemMap(items: Array<FilterItem>): { [key: number]: FilterItem } {
        const itemsMap: { [key: number]: FilterItem } = {};
        items.forEach(item => {
            itemsMap[item.id] = item;
        });
        return itemsMap;
    }

    /**
     * Returns selected filter items.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {Array<FilterItem>}
     * @memberof InteractionsChartDataService
     */
    private selectedFilterItems(items: Array<FilterItem>): Array<FilterItem> {
        const selectedItems = items ? items.filter(item => item.isSelected) : [];
        return selectedItems;
    }

}
