<ns-deliverable-configuration-header
    [headerText]="'platform.deliverable.correlations.label' | translate"
    [showBackButton]=true
    (backBtnClickEvent)="returnBack()"
    (closeBtnClickEvent)="onCloseButtonClick()"></ns-deliverable-configuration-header>
        <ng-template mat-tab-label>
            <span class="sif sif-list-select cursor-pointer"></span>
        </ng-template>
<div class="example-container deliverables-list">
    <table mat-table [dataSource]="metaInfoMap">
        <ng-container matColumnDef="correlationLabel">
            <th mat-header-cell *matHeaderCellDef class="first-column">  </th>
            <td mat-cell *matCellDef="let element"> {{element.correlationLabel}} </td>
        </ng-container>
        <ng-container matColumnDef="dataTable">
            <th mat-header-cell *matHeaderCellDef>
                <div>
                    DataTable
                    <mat-slide-toggle [(ngModel)]="toggleDataTableAllValues" (ngModelChange)="setAllValues('dataTable')"></mat-slide-toggle>
                </div> </th>
            <td mat-cell *matCellDef="let element">
                <span [ngClass]="element.dataTable ? 'sif sif-eye-enabled sif-12' : 'sif sif-eye-disabled sif-15'" (click)="toggleView(element,'dataTable') "></span>
            </td>
        </ng-container>
        <ng-container  matColumnDef="quadMap">
            <th mat-header-cell *matHeaderCellDef>
                <div>
                    Quad Map
                    <mat-slide-toggle [(ngModel)]="toggleQuadTableAllValues" (ngModelChange)="setAllValues('quadMap')"></mat-slide-toggle>
                </div> </th>
            <td mat-cell *matCellDef="let element">
                <span class="padding-left-1-point-5" [ngClass]="element.quadMap ? 'sif sif-eye-enabled sif-12' : 'sif sif-eye-disabled sif-15'" (click)="toggleView(element,'quadMap') "></span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
