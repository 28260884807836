import {switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {ReportService} from './report.service';
import {TranslateService} from '@ngx-translate/core';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {Injectable} from '@angular/core';
import {Deliverable} from '@platform/models/deliverable.model';
import {ProductDeliverableType} from '@platform/models/product-deliverable-type';
import {Report} from "@platform/models/report.model";
import {DeliverableType} from "@app/deliverables/deliverable-type.enum";
import {Router} from "@angular/router";

/**
 * This service provides operations for filtering and loading deliverable
 * infos.
 *
 * @example
 * constructor(private deliverableViewService: DeliverableViewService) { }
 *
 * @export
 * @class DeliverableInfoService
 */
@Injectable({
    providedIn: 'root'
})
export class DeliverableInfoService {

    constructor(private translate: TranslateService,
                private router: Router,
                private reportService: ReportService) {
    }

    /**
     * Get the deliverableInfo types from product and also deliverableTypes from reports and then Maps to a new array of deliverableInfos accordingly.
     * NOTE: deliverableTypes from reports always takes higher precedence to static values deliverableType in the @params
     * @param deliverableType : static values which comes from product
     * @returns the observable of array of deliverable infos.
     */
    public mapFromType(deliverableType: Record<string, ProductDeliverableType>, defaultMappingOfDeliverableInfo: boolean = false): Observable<DeliverableInfo[]> {
        let infos: DeliverableInfo[] = [];
        const report$ = this.reportService.get();
        return report$.pipe(switchMap(report => {
            const deliverables = report.deliverables.filter(deliverable => deliverable.isComplete);
            infos = this.mapFromReportDeliverables(deliverables, deliverableType, defaultMappingOfDeliverableInfo);
            infos = infos.filter(info => !!info);
            return [infos];
        }));
    }

    /**
     * Returns the mapped DeliverableInfos after comparing with Deliverables in Reports
     * @param deliverables
     * @param deliverableType
     * @param useDefaultPosition
     * @private
     */
    private mapFromReportDeliverables(deliverables: Deliverable[], deliverableType: Record<string, ProductDeliverableType>, useDefaultPosition: boolean): DeliverableInfo[] {
        let infos: DeliverableInfo[] = [];
        let defaultDeliverableInfo: ProductDeliverableType = null;
        Object.keys(deliverableType).forEach(key => {
            defaultDeliverableInfo = deliverableType[key];
            const deliverableTypeFromReport = deliverables.find(deliverable => deliverable.type === defaultDeliverableInfo.type);
            if (deliverableTypeFromReport) {
                infos.push({
                    type: defaultDeliverableInfo.type,
                    path: defaultDeliverableInfo.path,
                    position: useDefaultPosition ? defaultDeliverableInfo.position : (deliverableTypeFromReport.position ? deliverableTypeFromReport.position : defaultDeliverableInfo.position),
                    label: this.translate.instant(defaultDeliverableInfo.labelKey),
                    imagePath: defaultDeliverableInfo.imagePath,
                    description: this.translate.instant(defaultDeliverableInfo.descriptionKey),
                    showDeliverable: deliverableTypeFromReport.showDeliverable
                });
            }
        });
        return infos.sort((a, b) => a.position - b.position);
    }

    public checkNullPosition(deliverables: Deliverable[]): any {
        return deliverables.find(deliverable => deliverable.position === undefined);
    }

    /**
     * Return the new Deliverable Infos array with updated positions
     * @param existingDeliverableInfo
     */
    public getUpdatedDeliverableInfoPosition(existingDeliverableInfo: DeliverableInfo[]): DeliverableInfo[] {
        const tempDeliverableInfo: DeliverableInfo[] = [];
        existingDeliverableInfo.forEach((deliverableInfo, index) => {
            const updatedDeliverableInfo = {
                type: deliverableInfo.type,
                path: deliverableInfo.path,
                imagePath: deliverableInfo.imagePath,
                label: deliverableInfo.label,
                description: deliverableInfo.description,
                position: index,
                showDeliverable: deliverableInfo.showDeliverable
            };
            tempDeliverableInfo.push(updatedDeliverableInfo);
        });
        return tempDeliverableInfo;
    }

    /**
     * Check if Deliverable Exists
     */
    public isDeliverableExists(): Observable<boolean> {
        const report$ = this.reportService.get();
        return report$.pipe(switchMap(report => {
            return of(!!report.deliverables.filter(deliverable => deliverable.isComplete).length);
        }));
    }

    /**
     * Returns all the forecast deliverable types
     * */
    public getForecastDeliverableTypes(): { [key: string]: ProductDeliverableType } {
        const forecastDeliverableTypes = {};
        Object.keys(DeliverableType).forEach((it) => {
            if (DeliverableType[it].type.indexOf('forecast') === 0) {
                forecastDeliverableTypes[it] = DeliverableType[it];
            }
        })
        return forecastDeliverableTypes;
    }

    public getNonForecastDeliverableTypes(): { [key: string]: ProductDeliverableType } {
        const forecastDeliverableTypes = {};
        Object.keys(DeliverableType).forEach((it) => {
            if (DeliverableType[it].type.indexOf('forecast') !== 0) {
                forecastDeliverableTypes[it] = DeliverableType[it];
            }
        })
        return forecastDeliverableTypes;
    }

    public getForecastDeliverables(report: Report): DeliverableInfo[] {
        const infos: DeliverableInfo[] = [];
        let defaultForecastingInfo: DeliverableInfo;
        Object.keys(DeliverableType).forEach(key => {
            defaultForecastingInfo = DeliverableType[key];
            if (defaultForecastingInfo.type.indexOf('forecast') === 0) {
                const forecastingTypeFromReport = report.deliverables.find(deliverable => deliverable.type === defaultForecastingInfo.type);
                if (forecastingTypeFromReport) {
                    /* checking the position undefined is important else a value of 0
                     * will be considered false and it will cause the card in the 0th position
                     * to behave incorrectly
                     */
                    const newPosition = (forecastingTypeFromReport.position !== undefined &&
                        forecastingTypeFromReport.position !== defaultForecastingInfo.position) ?
                        forecastingTypeFromReport.position : defaultForecastingInfo.position;
                    infos.push({
                        type: defaultForecastingInfo.type,
                        path: defaultForecastingInfo.path,
                        position: newPosition,
                        label: this.translate.instant(defaultForecastingInfo.labelKey),
                        imagePath: defaultForecastingInfo.imagePath,
                        description: this.translate.instant(defaultForecastingInfo.descriptionKey),
                        showDeliverable: forecastingTypeFromReport.showDeliverable
                    });
                }
            }
        });
        return infos.sort((a, b) => a.position - b.position);
    }

    public getNonForecastDeliverables(report: Report): DeliverableInfo[] {
        const infos: DeliverableInfo[] = [];
        let defaultDeliverableInfo: DeliverableInfo;
        Object.keys(DeliverableType).forEach(key => {
            defaultDeliverableInfo = DeliverableType[key];
            if (defaultDeliverableInfo.type.indexOf('forecast') !== 0) {
                const deliverableTypeFromReport = report.deliverables.find(deliverable => deliverable.type === defaultDeliverableInfo.type && deliverable.isComplete);
                if (deliverableTypeFromReport) {
                    /* checking the position undefined is important else a value of 0
                     * will be considered false and it will cause the card in the 0th position
                     * to behave incorrectly
                     */
                    const newPosition = (deliverableTypeFromReport.position !== undefined &&
                        deliverableTypeFromReport.position !== defaultDeliverableInfo.position) ?
                        deliverableTypeFromReport.position : defaultDeliverableInfo.position;
                    infos.push({
                        type: defaultDeliverableInfo.type,
                        path: defaultDeliverableInfo.path,
                        position: newPosition,
                        label: this.translate.instant(defaultDeliverableInfo.labelKey),
                        imagePath: defaultDeliverableInfo.imagePath,
                        description: this.translate.instant(defaultDeliverableInfo.descriptionKey),
                        showDeliverable: deliverableTypeFromReport.showDeliverable
                    });
                }
            }
        });
        return infos.sort((a, b) => a.position - b.position);
    }

    routeToDeliverable(deliverableInfo: DeliverableInfo): void {
        const currentURL = this.router.url;
        const finalURL = currentURL.substr(0, currentURL.lastIndexOf('/')) + '/' + deliverableInfo.path;
        this.router.navigate([finalURL]);
    }

    /**
     * Returns deliverable info for given type name
     * */
    getDeliverableTypeByTypeName(type: string): ProductDeliverableType {
        return Object.keys(DeliverableType).map(it => DeliverableType[it]).find(it => it.type === type);
    }

    getDeliverableTypeByPath(path: string): DeliverableInfo {
        return Object.keys(DeliverableType).map(it => DeliverableType[it]).find(it => it.path === path);
    }

    /**
     * Filters out forecast deliverable info
     * */
    getNonForecastDeliverableInfos(deliverableInfos: DeliverableInfo[]) {
        return deliverableInfos.filter(it => !it.type.startsWith('forecast'));
    }

    /**
     * Filters out forecast deliverable info
     * */
    getForecastDeliverableInfos(deliverableInfos: DeliverableInfo[]) {
        return deliverableInfos.filter(it => it.type.startsWith('forecast'));
    }

    /**
     * This method will check if the cards are in default positions and are not moved and returns a boolean.
     * This boolean value can be used in the display of reset button.
     * @param deliverableInfos
     * @param deliverablesInReport
     * @private
     */
    public deliverablesAreInDefaultOrder(deliverableInfos: DeliverableInfo[]): boolean {
        let index = -1;
        let sortFunction = (a, b) => {
            return a.position - b.position;
        };
        let _deliverablesInReport = [...deliverableInfos].sort(sortFunction);
        let _deliverableInfos = Object.keys(DeliverableType).map(it => DeliverableType[it]).sort(sortFunction);
        return !_deliverablesInReport.some(reportDeliverable => {
            index = _deliverableInfos.findIndex(deliverableInfo => deliverableInfo.type === reportDeliverable.type);
            _deliverableInfos = _deliverableInfos.slice(index);
            return index === -1;
        });
    }

    /**
     * Get full path to redirect to deliverable page.
     * @param deliverablePath { string }
     */
    getDeliverableRedirectPath(deliverableInfoPath: string): string {
        return this.router.url
            .split("/").slice(0, -1)
            .concat(deliverableInfoPath).join("/");
    }
}
