import {ConceptDataItem, FactorsDeliverableView} from '@app/deliverables/factors/models/factors.model';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FactorsMapping} from '@app/deliverables/factors/models/factors-mapping';
import {ThresholdsMapping} from '@app/deliverables/factors/models/thresholds-mapping';
import {TranslateService} from '@ngx-translate/core';
import {ThresholdsDisplay} from '@app/deliverables/factors/models/thresholds-display';
import {FactorsDisplay} from '@app/deliverables/factors/models/factors-display';
import {FactorsFilter, FilterItem} from '../models/filter.model';
import {ProbabilityOfSuccessMapping} from '@app/deliverables/factors/models/probability-of-success-mapping';
import {FactorsService} from '@app/deliverables/factors/services/factors.service';

/**
 * `ColumnsViewService` has all data operations
 * for fetching the factors for success deliverable data for concepts view
 *
 * @export
 * @class ColumnsViewService
 */

@Injectable({
    providedIn: 'root'
})
export class ColumnsViewService {

    /**
     * This Array is to store list of primary competitive sets
     * @private
     */
    private primaryCompetitiveSet = [];

    /**
     * This Array is to store list of primary competitive sets text that shows in legend
     * @private
     */
    private primaryCompetitiveText = [];

    /**
     * This is to emit primary competitive sets updated text to show in legend
     * @private
     */
    public primaryCompetitiveSubject = new BehaviorSubject<String[]>([]);


    constructor(
        private translate: TranslateService,
        private factorService: FactorsService) {
    }

    /**
     * Returns a tuple of column headers and factors data for MatTable
     * Column headers: ['concept', 'probabilityOfSuccess', 'outstanding', 'ready', 'risky', 'highRisk'];
     * { concept: 'Cinnamon Toast Crunch',probabilityOfSuccess: ['High Risk'], outstanding: ['As', 'Dp'],
     * ready: ['AC', 'Cr'], risky: ['--'], highRisk: ['ND', 'Ad'] }
     *
     * @param {FactorsFilter} filter
     * @param {FactorsDeliverableView} factors
     * @param isAlcoholStudy
     * @param isCannabisStudy
     * @returns {[Array<any>, Array<any>]} - FFS table data for concepts view
     */
    public getConceptsChartData(filter: FactorsFilter, factors: FactorsDeliverableView, isAlcoholStudy: boolean, isCannabisStudy: boolean): [Array<any>, Array<any>] {
        // read from meta info here if it's an alcohol study
        const tableData = [];
        this.primaryCompetitiveSet = [];
        this.primaryCompetitiveText = [];
        const selectedConcepts = this.factorService.selectedFilterItems(filter.concepts);
        const importedConcepts = filter.concepts.filter(it => it.isImported);
        const selectedSubgroups = this.factorService.selectedFilterItems(filter.subgroups);
        const selectedFactors = this.factorService.selectedFilterItems(filter.show.factorsOptions);
        const selectedThresholds = this.factorService.selectedFilterItems(filter.show.thresholdOptions);
        selectedConcepts.sort(function (a, b) {
            return a.position - b.position;
        });
        const filteredFactor = this.getFilteredAndSortedFactorConceptData(factors, selectedConcepts, selectedSubgroups, importedConcepts);
        filteredFactor.forEach((dataItem) => {
            const rowData: any = {};
            rowData.concept = this.getRowHeader(filter.concepts, dataItem);
            if (factors.probabilityOfSuccess) {
                rowData[this.translate.instant('quick.predict.deliverables.factors.probability.of.success')] = factors.messagingCommunicationFactors ?
                    this.getProbabilityOfSuccess(dataItem.conditionalProbabilityOfSuccess) : this.getProbabilityOfSuccess(dataItem.snapshotConditionalProbabilityOfSuccess);
            }
            selectedThresholds.forEach(threshold => {
                rowData[threshold.name] = this.getFactorGrouping(dataItem, selectedFactors, threshold, isAlcoholStudy, isCannabisStudy);
            });
            tableData.push(rowData);
        });
        this.primaryCompetitiveSubject.next(this.primaryCompetitiveText);
        return [this.getColumnHeader(selectedThresholds, factors), tableData];
    }

    /**
     * Filter the factor concepts and sort them
     * @param factors
     * @param selectedConcepts
     * @param selectedSubgroups
     * @param importedConcepts
     * @private
     */
    private getFilteredAndSortedFactorConceptData(factors: FactorsDeliverableView, selectedConcepts: FilterItem[],  selectedSubgroups: FilterItem[], importedConcepts?: FilterItem[]) {
        const filteredConcepts = factors.concepts.filter(factorConcept => {
            const isImportedConcept = importedConcepts?.find(it => it.id === factorConcept.conceptId);
            if (isImportedConcept) {
                return selectedConcepts.some(selected => selected.id === factorConcept.conceptId);
            } else {
                return selectedConcepts.some(selected => selected.id === factorConcept.conceptId) &&
                    selectedSubgroups.some(selected => selected.id === factorConcept.segmentId);
            }
        });
        return filteredConcepts.sort(function (a, b) {
            return selectedConcepts.findIndex(c => c.id === a.conceptId) - selectedConcepts.findIndex(c => c.id === b.conceptId);
        });
    }

    /**
     * Returns the probability of success text and corresponding tooltip - Outstanding, Ready, Risky High Risk
     *
     * @private
     * @param {number} value
     * @returns FactorsDisplay
     * @memberOf ColumnsViewService
     */
    private getProbabilityOfSuccess(value: number): Array<Object> {
        const probOfSuccessDetails = [];
        if (value >= ProbabilityOfSuccessMapping.HIGH_RISK.minimum && value <= ProbabilityOfSuccessMapping.HIGH_RISK.maximum) {
            probOfSuccessDetails.push(this.getThresholdsForProbabilityOfSuccess('HIGH_RISK'));
        } else if (value >= ProbabilityOfSuccessMapping.RISKY.minimum && value <= ProbabilityOfSuccessMapping.RISKY.maximum) {
            probOfSuccessDetails.push(this.getThresholdsForProbabilityOfSuccess('RISKY'));
        } else if (value >= ProbabilityOfSuccessMapping.READY.minimum && value <= ProbabilityOfSuccessMapping.READY.maximum) {
            probOfSuccessDetails.push(this.getThresholdsForProbabilityOfSuccess('READY'));
        } else if (value >= ProbabilityOfSuccessMapping.OUTSTANDING.minimum && value <= ProbabilityOfSuccessMapping.OUTSTANDING.maximum) {
            probOfSuccessDetails.push(this.getThresholdsForProbabilityOfSuccess('OUTSTANDING'));
        }
        return probOfSuccessDetails;
    }

    /**
     * Return the object to build the probability of Success
     * @param thresholdKey
     * @private
     */
    private getThresholdsForProbabilityOfSuccess(thresholdKey: string) {
        const probOfSuccessObject: FactorsDisplay = new FactorsDisplay();
        probOfSuccessObject.code = this.translate.instant(ThresholdsMapping[thresholdKey].columnText);
        probOfSuccessObject.tooltip = this.translate.instant(ThresholdsMapping[thresholdKey].tooltip);
        probOfSuccessObject.color = ThresholdsMapping[thresholdKey].color;
        return probOfSuccessObject;
    }

    /**
     * Returns the array of factors that falls within the given minimum and maximum range for given conceptData
     * distinctProposition: 50, attentionCatching: 40, needDesire: 30, advantage: 28, credibility: 58, acceptableCosts: 66
     * Outstanding [85,100], Ready[67,84], Risky[33,66], High Risk[0,32]
     *
     * @param  conceptData
     * @param selectedFactorsfilter
     * @param {FilterItem} threshold
     * @param {boolean} isAlcoholStudy
     * @param {boolean} isCannabisStudy
     * @returns Array<FactorsDisplay>
     * @memberOf ColumnsViewService
     */
    private getFactorGrouping(conceptData: ConceptDataItem, selectedFactorsfilter: Array<FilterItem>, threshold: FilterItem, isAlcoholStudy: boolean, isCannabisStudy: boolean): FactorsDisplay[] {
        const factorObject: Array<FactorsDisplay> = [];
        const factorOptions = FactorsMapping;
        let successOption: ThresholdsDisplay;
        for (const key in ThresholdsMapping) {
            if (this.translate.instant(ThresholdsMapping[key].columnText) === threshold.name) {
                successOption = ThresholdsMapping[key];
            }
        }
        for (const key in factorOptions) {
            const factor: FactorsDisplay = new FactorsDisplay();
            const factorValue = conceptData[factorOptions[key].id];
            selectedFactorsfilter.forEach(factors => {
                const factorText = (isAlcoholStudy || isCannabisStudy) && this.translate.instant(factorOptions.NEED_DESIRE.factorText) === this.translate.instant(factorOptions[key].factorText) ? this.translate.instant(factorOptions.DESIRE.factorText) : this.translate.instant(factorOptions[key].factorText);
                if (factorText === factors.name) {
                    if (factorValue === this.translate.instant(successOption.name)) {
                        if ((isAlcoholStudy || isCannabisStudy) && factorOptions[key].code === factorOptions.NEED_DESIRE.code) {
                            factor.code = factorOptions.DESIRE.code;
                        } else {
                            factor.code = factorOptions[key].code;
                        }
                        factor.tooltip = this.getFactorTooltip(factorOptions[key].code, successOption);
                        factor.color = successOption.color;
                        factorObject.push(factor);
                    }
                }
            });
        }
        this.changesForBlankFactorArray(factorObject);
        return factorObject;
    }

    /**
     * Returns the array of factor tooltips
     *
     * @param {string} code
     * @param {ThresholdsDisplay} successOption
     * @returns string
     * @memberOf ColumnsViewService
     */
    public getFactorTooltip(code: string, successOption: ThresholdsDisplay): string {
        switch (code) {
            case FactorsMapping.DISTINCT_PROPOSITION.code:
                return this.translate.instant(successOption.DP);
            case FactorsMapping.ATTENTION_CATCHING.code:
                return this.translate.instant(successOption.AC);
            case FactorsMapping.MESSAGE_CONNECTION.code:
                return this.translate.instant(successOption.MC);
            case FactorsMapping.CLEAR_CONCISE_MESSAGE.code:
                return this.translate.instant(successOption.CM);
            case FactorsMapping.NEED_DESIRE.code:
                return this.translate.instant(successOption.ND);
            case FactorsMapping.ADVANTAGE.code:
                return this.translate.instant(successOption.Ad);
            case FactorsMapping.CREDIBILITY.code:
                return this.translate.instant(successOption.Cr);
            case FactorsMapping.ACCEPTABLE_COSTS.code:
                return this.translate.instant(successOption.A$);
        }
    }


    /**
     * Returns '--' if there are no factors falling in successOption bucket as needed in the UI
     *
     * @memberOf ColumnsViewService
     * @param factorObject
     */
    private changesForBlankFactorArray(factorObject: Array<FactorsDisplay>): void {
        if (factorObject.length === 0) {
            const factor: FactorsDisplay = new FactorsDisplay();
            factor.code = '--';
            factor.tooltip = '';
            factorObject.push(factor);
        }
    }

    /**
     * Returns the row header in concept view
     *
     * @private
     * @param {Array<FilterItem>} concepts
     * @param {ConceptDataItem}conceptData
     * @memberOf ColumnsViewService
     */
    private getRowHeader(concepts: Array<FilterItem>, conceptData: ConceptDataItem) {
        const concept: any = {};
        if (!this.primaryCompetitiveSet.includes(conceptData.primaryCompetitiveSet)) {
            this.primaryCompetitiveSet.push(conceptData.primaryCompetitiveSet);
            const text = conceptData.primaryCompetitiveSet + ' (' + conceptData.primaryCompetitiveSetBaseSize + ' concepts)';
            this.primaryCompetitiveText.push(text);
        }
        const index = this.primaryCompetitiveSet.indexOf(conceptData.primaryCompetitiveSet);
        const selectedConcept = concepts.find((item) => item.id === conceptData.conceptId);
        concept.name = selectedConcept.name;
        concept.suffix = index + 1;
        concept.isImported = selectedConcept.isImported;
        concept.reportName = selectedConcept.reportName;
        return concept;
    }

    /**
     * Returns the column header in concept view
     *
     * @private
     * @memberOf ColumnsViewService
     * @param selectedThresholds
     * @param factors
     */
    getColumnHeader(selectedThresholds: Array<FilterItem>, factors: FactorsDeliverableView) {
        const colheaders = [];
        selectedThresholds.forEach(threshold => {
            const header: any = {};
            header.name = threshold.name;
            for (const key in ThresholdsMapping) {
                if (this.translate.instant(ThresholdsMapping[key].columnText) === threshold.name) {
                    header.color = ThresholdsMapping[key].color;
                }
            }
            colheaders.push(header);
        });
        if (factors.probabilityOfSuccess) {
            colheaders.unshift({
                name: this.translate.instant('quick.predict.deliverables.factors.probability.of.success'),
                color: 'threshold-grey'
            });
        }
        colheaders.unshift({name: 'concept'});
        return colheaders;
    }

}
