import {FactorsService} from '@app/deliverables/factors/services/factors.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {ColumnsViewService} from '@app/deliverables/factors/services/concepts-view.service';
import {DeliverableViewType} from '@app/deliverables/factors/models/deliverable-view-type.enum';
import {ReportService} from '@platform/services/report.service';
import {FactorsDeliverableView} from '@app/deliverables/factors/models/factors.model';
import {FactorsFilter} from '@app/deliverables/factors/models/filter.model';

/**
 * `<ns-factors-legend>` creates the factors for success legend
 *
 * @example
 * <ns-factors-legend></ns-factors-legend>
 *
 * @export
 * @class LegendComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'ns-factors-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit, OnDestroy {

    /**
     * Factors For Success filter object.
     *
     * @property
     * @type {FactorsFilter}
     * @memberof LegendComponent
     */
    public filter: FactorsFilter;


    /**
     * Factors deliverable view data.
     *
     * @type {FactorsDeliverableView}
     * @member LegendComponent
     */
    public factorsDeliverableView: FactorsDeliverableView;

    /**
     * List of subscriptions for cleanup.
     *
     * @private
     * @type {Array<Subscription>}
     * @memberof LegendComponent
     */
    private subscriptions: Array<Subscription>;

    /**
     * FFS has 2 views: (1) Concepts view (2) Subgroup Means view
     * @type {boolean}
     * @memberof LegendComponent
     */
    public isConceptView = true;

    /**
     * Boolean to determine alcohol study
     * @type {boolean}
     * @memberof LegendComponent
     */
    public isAlcoholStudy = false;

    /**
     * Boolean to determine cannabis study
     * @type {boolean}
     * @memberof LegendComponent
     */
    public isCannabisStudy = false;

    /**
     * This Array is to store list of primary competitive sets text that shows in legend
     * @public
     * @memberof LegendComponent
     */
    public primaryCompetitiveText: Array<String> = [];


    /**
     * Creates an instance of LegendComponent.
     *
     * @constructor
     * @param {factorsService} factorsService
     * @param {ColumnsViewService} columnsViewService
     * @param reportService
     * @memberof LegendComponent
     */
    constructor(private columnsViewService: ColumnsViewService, private factorsService: FactorsService, private reportService: ReportService) {
        this.subscriptions = [];
    }

    /**
     * Initializes the component with filter data.
     *
     * @memberof LegendComponent
     */
    ngOnInit(): void {
        const filter$ = this.factorsService.getFactorsFilter();
        const factors$ = this.factorsService.getFactors();
        const report$ = this.reportService.get();
        const subscription = combineLatest([factors$, filter$, report$]).subscribe(([factors, filters, report]) => {
            this.factorsDeliverableView = factors;
            this.filter = filters;
            filters.deliverableViewType === DeliverableViewType.CONCEPT ? this.isConceptView = true : this.isConceptView = false;
            report.projectType && report.projectType.toLowerCase() === 'alcohol' ? this.isAlcoholStudy = true : this.isAlcoholStudy = false;
            report.projectType && report.projectType.toLowerCase() === 'cannabis' ? this.isCannabisStudy = true : this.isCannabisStudy = false;
        });
        const textSubscription = this.columnsViewService.primaryCompetitiveSubject.subscribe(text => {
            this.primaryCompetitiveText = text;
        });
        this.subscriptions.push(subscription, textSubscription);
    }

    /**
     * Cleans up the component on removing from the UI.
     *
     * @memberof LegendComponent
     */
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
