import { DeliverableInfoService } from '@platform/services/deliverable-info.service';
import { ProductServiceFactoryImpl } from './product-factory-impl.service';
import { ProductServiceFactory } from '@platform/services/product-factory.service';
import { RouterService } from '@platform/services/router.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ForecastingService } from '@platform/services/forecasting.service';

const productFactory = (
  routerService: RouterService,
  translate: TranslateService,
  deliverableInfoService: DeliverableInfoService,
  forecastingService: ForecastingService) => {
  return new ProductServiceFactoryImpl(routerService, translate, deliverableInfoService, forecastingService);
};
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ]
})
export class ProductsModule {
  /**
   * `forRoot()` method returns a `ModuleWithProviders` object which provides
   * `root` modules with the shared service instances.
   *
   * @static
   * @returns {ModuleWithProviders}
   * @memberof ProductsModule
   */
  static forRoot(): ModuleWithProviders<ProductsModule> {
    return {
      ngModule: ProductsModule,
      providers: [
        {
          provide : ProductServiceFactory,
          useFactory : productFactory,
          deps : [ RouterService, TranslateService, DeliverableInfoService, ForecastingService ]
        }
      ]
    };
  }
}
