<mat-card class="concept-card" *ngIf="concept" [ngClass]="{'imported disabled': isCardDisabled}">
  <div class="concept-card-header">
    <h3 class="concept-name">{{concept?.name}}</h3>
    <div class="sample-group text-grey-2">{{subgroup?.name}}</div>
  </div>
  <mat-card-content>
    <div class="card-content">
      <mat-checkbox class="toggle-concepts" [checked]="conceptAdded || isImported(importedConceptIds, concept.exerciseConceptId)" (change)="toggleSelect()" 
        [disabled]="isCardDisabled"
        [matTooltip]="disabledCheckboxIncompleteDetailsTooltip" [matTooltipClass]="['custom-tooltip', 'pre-tooltip']"></mat-checkbox>
      <img [src]="conceptImageUrl" alt="Icon" class="concept-image-thumbnail padding-left-1-point-5 padding-right-1-point-5" (mouseenter) = "showFullSizeConcept()" (mouseleave)="closeFullSizeConcept()">
      <div *ngIf="!factorsDataForConcept || !fpMeasureDataForConcept">No Deliverable Data Found</div>
      <ns-benchmark-concept-factors
        class="padding-top-1 padding-left-1-point-5 padding-right-1-point-5"
        *ngIf="factorsDataForConcept && fpMeasureDataForConcept"
        [factorsDataForConcept]="factorsDataForConcept"
        [concept]="concept"
        [report]="report"></ns-benchmark-concept-factors>
      <div *ngIf="factorsDataForConcept && fpMeasureDataForConcept" class="separator"></div>
      <ns-benchmark-concept-financial-potential
        class="padding-top-1 padding-left-1-point-5 padding-right-1-point-5"
        *ngIf="factorsDataForConcept && fpMeasureDataForConcept"
        [measureDataForConcept]="fpMeasureDataForConcept"
        [showPurchaseIntent]="fpShowPurchaseIntent"
        [showFrequency]="fpShowFrequency"
        [showUnits]="fpShowUnits"
        [concept]="concept"
        [report]="report"></ns-benchmark-concept-financial-potential>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #fullSizeConceptImageDialog>
  <img [src]="conceptImageUrl" alt="full size concept image" class="concept-image-full">
</ng-template>
