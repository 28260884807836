import {Subgroup} from '@platform/models/subgroup.model';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {FactorsFilter, FilterItem} from '../models/filter.model';
import {FilterService} from '@platform/services/filter.service';
import {Factor, FactorsDeliverableView} from '@app/deliverables/factors/models/factors.model';
import {Injectable} from '@angular/core';
import {Observable, Subject, zip} from 'rxjs';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {switchMap, take} from 'rxjs/operators';
import {defaultFactorsFilter} from '@app/deliverables/factors/models/default-factors-filter';
import {ReportService} from '@platform/services/report.service';
import {SubgroupService} from '@platform/services/subgroup.service';
import {ConceptService} from '@platform/services/concept.service';
import {Concept} from '@platform/models/concept.model';
import {CompareView} from '@app/deliverables/factors/models/compare-view-enum';
import {TranslateService} from '@ngx-translate/core';
import {FactorsMapping} from '../models/factors-mapping';
import {DeliverableViewType} from '../models/deliverable-view-type.enum';
import {FactorMeasuretypeMapping} from '../models/factor-measuretype-mapping-enum';
import {BenchmarkService} from '@platform/services/benchmark.service';
import {Benchmark} from '@platform/models/benchmark.model';

/**
 * `FactorsService` has all data operations
 * for fetching the factors for success filter data
 *
 * @export
 * @class FactorsService
 */

@Injectable({
    providedIn: 'root'
})
export class FactorsService {

    public visibleColumnsSubject = new Subject<number[]>();

    public deliverableHeaderSubject = new Subject<boolean>();

    /**
     * Factors For Success default filter object.
     *
     * @property
     * @type {FactorsFilter}
     * @memberof FactorsService
     */
    public defaultFilter: FactorsFilter;


    /**
     * Creates an instance of FactorsService.
     *
     * @constructor
     * @param {DeliverableViewService} deliverableViewService
     * @param {ReportService} reportService
     * @param {ConceptService} conceptService
     * @param {SubgroupService} subgroupService
     * @param {FilterService} filterService
     * @param translate
     * @memberof FactorsService
     */
    constructor(
        private deliverableViewService: DeliverableViewService,
        private reportService: ReportService,
        private conceptService: ConceptService,
        private subgroupService: SubgroupService,
        private filterService: FilterService,
        private translate: TranslateService,
        private benchmarkService: BenchmarkService) {
    }

    /**
     * Returns an observable of `FactorsDeliverableView` data.
     *
     * @returns {Observable<FactorsDeliverableView>}
     * @memberof FactorsService
     */
    public getFactors(): Observable<FactorsDeliverableView> {
        const deliverableType = DeliverableType.FACTORS.type;
        const filter$ = this.getFactorsFilter();
        const factors$: Observable<FactorsDeliverableView> = filter$.pipe(
            switchMap(filter => {
                return this.deliverableViewService.filter<FactorsDeliverableView, FactorsFilter>(
                    filter.deliverableViewType,
                    deliverableType,
                    this.filter.bind(this)
                );
            })
        );
        return factors$;
    }

    /**
     * Filter for filtering the Factors For Success data and returns
     * filtered `FactorsDeliverableView` data.
     *
     * @private
     * @param {FactorsFilter} filter
     * @param {FactorsDeliverableView} data
     * @returns {FactorsDeliverableView}
     * @memberof FactorsService
     */
    private filter(filter: FactorsFilter, data: FactorsDeliverableView): FactorsDeliverableView {
        const selectedConcepts = filter.concepts.filter(c => c.isSelected).map(c => c.id);
        const selectedSubgroups = filter.subgroups.filter(s => s.isSelected).map(s => s.id);
        const importedConcepts = filter.concepts.filter(it => it.isImported).map(s => s.id);

        let filteredView;
        let filteredData;

        if (data.viewName === CompareView.SUBGROUP) {
            const filteredFactors = data.factors.filter(item => selectedConcepts.includes(item.conceptId));
            filteredData = filteredFactors.map(factor => {
                const newFactor: Factor = Object.assign({}, factor);
                newFactor.subgroups = factor.subgroups.filter(subgroup => selectedSubgroups.includes(subgroup.segmentId));
                return newFactor;
            });
            const filteredBaseSize = data.baseSizes.filter(item => selectedConcepts.includes(item.conceptId));
            filteredView = Object.assign({}, data, {factors: filteredData, baseSizes: filteredBaseSize});
        } else {
            filteredData = data.concepts.filter(
                item =>  importedConcepts.includes(item.conceptId) ? selectedConcepts.includes(item.conceptId) :
                    selectedConcepts.includes(item.conceptId) && selectedSubgroups.includes(item.segmentId)
            );
            filteredView = Object.assign({}, data, {concepts: filteredData});
        }
        return filteredView;
    }

    /**
     * Returns observable of Factors for success filter data.
     *
     * @returns {Observable<FactorsFilter>}
     * @memberof FactorsService
     */
    public getFactorsFilter(): Observable<FactorsFilter> {
        const deliverableType = DeliverableType.FACTORS.type;
        const filter$ = this.filterService.get<FactorsFilter>(deliverableType);
        return filter$;
    }

    /**
     *  filters subgroups based on databaseable parameter
     *
     * @param {FilterItem[]} subgroups
     * @param {string} deliverableViewType
     * @memberof FactorsService
     */
    public filterSubgroups(subgroups: FilterItem[], deliverableViewType: string) {
        subgroups = deliverableViewType === DeliverableViewType.CONCEPT ? subgroups.filter(subgroup => subgroup.isDatabaseable) : subgroups;
        return subgroups;
    }

    /**
     * modifies show filter options based on view
     *
     * @public
     * @param {FactorsFilter} filter
     * @param {string} deliverableViewType
     * @param {FactorsDeliverableView} factors
     * @param {boolean} isAlcoholStudy
     * @param {boolean} isCannabisStudy
     * @memberof FactorsService
     */
    public modifyShowFilter(filter: FactorsFilter, deliverableViewType: string, factors: FactorsDeliverableView, isAlcoholStudy: boolean, isCannabisStudy: boolean) {
        if (!factors.messagingCommunicationFactors) {
            filter.show.factorsOptions = filter.show.factorsOptions.filter(factor => factor.id !== FactorsMapping.MESSAGE_CONNECTION.id && factor.id !== FactorsMapping.CLEAR_CONCISE_MESSAGE.id);
        }
        if (isAlcoholStudy || isCannabisStudy) {
            filter.show.factorsOptions = filter.show.factorsOptions.filter(factor => factor.id !== FactorsMapping.NEED_DESIRE.id);
        } else {
            filter.show.factorsOptions = filter.show.factorsOptions.filter(factor => factor.id !== FactorsMapping.DESIRE.id);
        }
        filter.show.factorsOptions = filter.show.factorsOptions.map(factor => {
            if (deliverableViewType === DeliverableViewType.CONCEPT) {
                if (factor.id === FactorsMapping.DISTINCT_PROPOSITION.id) {
                    factor.name = this.translate.instant(FactorsMapping.DISTINCT_PROPOSITION.factorText);
                }
                if (factor.id === FactorsMapping.ATTENTION_CATCHING.id) {
                    factor.name = this.translate.instant(FactorsMapping.ATTENTION_CATCHING.factorText);
                }
                if (factor.id === FactorsMapping.MESSAGE_CONNECTION.id) {
                    factor.name = this.translate.instant(FactorsMapping.MESSAGE_CONNECTION.factorText);
                }
                if (factor.id === FactorsMapping.CLEAR_CONCISE_MESSAGE.id) {
                    factor.name = this.translate.instant(FactorsMapping.CLEAR_CONCISE_MESSAGE.factorText);
                }
                return factor;
            } else {
                if (factor.id === FactorsMapping.DISTINCT_PROPOSITION.id) {
                    factor.name = this.translate.instant(FactorMeasuretypeMapping.ALTMT);
                }
                if (factor.id === FactorsMapping.ATTENTION_CATCHING.id) {
                    factor.name = this.translate.instant(FactorMeasuretypeMapping.NAD);
                }
                if (factor.id === FactorsMapping.MESSAGE_CONNECTION.id) {
                    factor.name = this.translate.instant(FactorMeasuretypeMapping.SIMT);
                }
                if (factor.id === FactorsMapping.CLEAR_CONCISE_MESSAGE.id) {
                    factor.name = this.translate.instant(FactorMeasuretypeMapping.CLRT);
                }
                return factor;
            }
        });
        deliverableViewType === DeliverableViewType.CONCEPT ? delete filter.show.decimals && delete filter.show.statTesting : delete filter.show.thresholdOptions;
        return filter.show;
    }

    /**
     * Loads the default factors for success filter data.
     *
     * @memberof FactorsService
     */
    public loadDefaultFilter(concept?: Concept): void {
        const defaultFilter: FactorsFilter = Object.assign({}, defaultFactorsFilter);
        const report$ = this.reportService.get();
        const concepts$ = this.conceptService.getReportDeliverableConceptsWithBenchmarks(DeliverableType.FACTORS.type);
        const subgroups$ = this.subgroupService.getReportSubgroups();
        const benchmarks$ = this.benchmarkService.getBenchMark();
        zip(report$, concepts$, subgroups$, benchmarks$).pipe(take(1)).subscribe(result => {
            defaultFilter.countries = [result[0].country];
            const concepts = concept ? [concept] : result[1];
            const hasBenchmarkConcepts = result[3].length > 0;
            if (hasBenchmarkConcepts) {
                defaultFilter.concepts = this.getConceptFiltersWithBenchmarks(concepts, result[3]);
            } else {
                defaultFilter.concepts = this.getConceptFilters(concepts);
            }
            defaultFilter.subgroups = this.getSubgroupFilters(result[2]);
            this.defaultFilter = defaultFilter;
            this.filterService.update(defaultFilter);
        });
    }

    /**
     * Returns concept filter items.
     *
     * @private
     * @param {Array<Concept>} concepts
     * @returns {Array<FilterItem>}
     * @memberof FactorsService
     */
    private getConceptFilters(concepts: Array<Concept>): Array<FilterItem> {
        const conceptFilterItems: Array<FilterItem> = [];
        for (const item of concepts) {
            conceptFilterItems.push({
                name: item.name,
                id: item.exerciseConceptId,
                isSelected: true,
                position: item.position,
                isImported: false
            });
        }
        return conceptFilterItems;
    }

    private getConceptFiltersWithBenchmarks(concepts: Array<Concept>, benchmarks: Array<Benchmark>): Array<FilterItem> {
        const conceptFilterItems: Array<FilterItem> = [];
        const benchmarkConceptIds = [];
        const benchmarkReports = new Map();
        // navigate through benchmarks array to get report and concept details so that we can use it in Concept filter mapping
        benchmarks.forEach(benchmark => {
            if (!benchmarkReports.get(benchmark.benchmarkReportId)) {
                // here capture the reportId and report name in a Map -- require an API from backend for reportNames
                benchmarkReports.set(benchmark.benchmarkReportId, benchmark.benchmarkReportName);
            }
            // here capture all the benchmark conceptId's
            benchmarkConceptIds.push(benchmark.benchmarkConceptId);
        });
        for (const item of concepts) {
            if (benchmarkConceptIds.includes(item.id)) {
                const benchmarkConcept = benchmarks.find(it => it.benchmarkConceptId === item.id);
                // for Benchmark Concept call them imported true and add the report name
                conceptFilterItems.push({
                    name: benchmarkConcept.displayName ? benchmarkConcept.displayName : item.name,
                    id: item.exerciseConceptId,
                    isSelected: true,
                    position: item.position,
                    isImported: true,
                    reportName: benchmarkReports.get(item.reportId)
                });
            } else {
                // For all original concepts call them imported false
                conceptFilterItems.push({
                    name: item.name,
                    id: item.exerciseConceptId,
                    isSelected: true,
                    position: item.position,
                    isImported: false
                });
            }
        }
        return conceptFilterItems;
    }

    /**
     * Returns subgroup filter items.
     *
     * @private
     * @param {Array<Subgroup>} subgroups
     * @returns {Array<FilterItem>}
     * @memberof FactorsService
     */
    private getSubgroupFilters(subgroups: Array<Subgroup>): Array<FilterItem> {
        const subgroupFilterItems: Array<FilterItem> = [];
        for (const item of subgroups) {
            subgroupFilterItems.push({
                name: item.name,
                id: item.segmentId,
                isSelected: false,
                position: item.position,
                isDatabaseable: item.isDatabaseable,
                isImported: false
            });
        }
        const dbSubgroupIndex = subgroupFilterItems.findIndex(it => it.isDatabaseable);
        if (dbSubgroupIndex >= 0) {
            subgroupFilterItems[dbSubgroupIndex].isSelected = true;
        } else {
            subgroupFilterItems[0].isSelected = true;
        }
        return subgroupFilterItems;
    }

    /**
     * Returns selected filter items.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {Array<FilterItem>}
     * @memberof FactorsService
     */
    public selectedFilterItems(items: Array<FilterItem>): Array<FilterItem> {
        const selectedItems = items ? items.filter(item => item.isSelected) : [];
        return selectedItems;
    }
}
