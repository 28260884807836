import {Concept} from '@platform/models/concept.model';
import {ScoreCardView} from '@platform/score-cards/score-card-view';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, forkJoin, Subscription, switchMap} from 'rxjs';
import {StringHelper} from '@platform/services/string-helper.service';
import {UserService} from '@platform/services/user.service';
import {ExportPngService} from '@platform/services/export-png.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent, MixpanelLabel} from '@src/assets/utils/mixpanel-enum';
import {FinancialPotentialFilter} from '@app/deliverables/financial-potential/models/filter.model';
import {FinancialPotentialDeliverableView} from './models/financial-potential.model';
import {FinancialPotentialService} from '@app/deliverables/financial-potential/services/financial-potential.service';
import {FinancialPotentialChartDataService} from './services/financial-potential-chart-data.service';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
import {ReportService} from '@platform/services/report.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {take} from 'rxjs/operators';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {UserView} from '@platform/models/user-view.model';
import {RouterService} from '@platform/services/router.service';
import {Report} from '@platform/models/report.model';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {UserViewService} from '@platform/services/user-view.service';
import {InsightService} from '@platform/insights/insights.service';
import {FilterService} from '@platform/services/filter.service';

@Component({
    selector: 'ns-financial-potential',
    templateUrl: './financial-potential.component.html',
    styleUrls: ['./financial-potential.component.scss']
})
export class FinancialPotentialComponent implements OnInit, OnDestroy, ScoreCardView {
    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @member FinancialPotentialComponent
     */
    public subscriptions: Array<Subscription>;
    /**
     * FinancialPotential deliverable view filter object.
     *
     * @type {FinancialPotentialFilter}
     * @member FinancialPotentialComponent
     */
    public filter: FinancialPotentialFilter;

    /**
     * is the selected subgroup Databasable
     *
     * @type {boolean}
     * @member FinancialPotentialComponent
     */
    public isSubgroupDatabasable: boolean;

    /**
     * Is the view Concepts View
     *
     * @type {boolean}
     * @member FinancialPotentialComponent
     */
    public isConceptView: boolean;

    /**
     * Is the Report having any Alcohol DataSet information
     *
     * @type {boolean}
     * @member FinancialPotentialComponent
     */
    public isAlcoholStudy: boolean;

    /**
     * Datasource object for the angular material table.
     *
     * @member FinancialPotentialComponent
     */
    public dataSource: Array<any> = [] ;

    /**
     * Array of displayed columns keys.
     *
     * @type {Array<string>}
     * @member FinancialPotentialComponent
     */
    public displayedColumns: string[];

    /**
     * The FinancialPotentialDeliverableView.
     * @type {FinancialPotentialDeliverableView} financialPotentialDeliverableView
     * @memberOf FinancialPotentialComponent
     */
    public financialPotentialDeliverableView: FinancialPotentialDeliverableView;

    /**
     * The Internal user
     */
    public isInternalUser: Boolean;

    /**
     * toggle insight btn
     * @type {Boolean} isInsightEnable
     * @memberOf FinancialPotentialComponent
     */
    public isInsightEnable = false;

    /**
     *
     * @param Import Concept params
     */
    public isImport = false;

    /**
     * Spinner.
     *
     * @type {Array<any>}
     * @member AttributesComponent
     */
    public displayProgressSpinner = false;

    /**
     *
     */
    public disableBtn: boolean;

    /**
     * deliverable Data for insights creations.
     *
     * @type {number[]}
     * @memberOf SurveyQuestionComponent
     */
    public deliverableData: DeliverableInsight;

    /**
     * ScoreCard Concept object for FP.
     *
     * @type {Concept}
     */
    public scoreCardConcept: Concept;

    public deliverableViews: Array<DeliverableView>;

    public deliverableInfos: Array<DeliverableInfo>;

    public userViews: Array<UserView>;

    public report: Report;

    public deliverableType = DeliverableType.FINANCIAL_POTENTIAL.type;

    constructor(
        private financialPotentialService: FinancialPotentialService,
        private chartDataService: FinancialPotentialChartDataService,
        private stringHelper: StringHelper,
        private userService: UserService,
        private exportPNGService: ExportPngService,
        private spinnerService: SpinnerService,
        private deliverableInsightService: DeliverableInsightService,
        private mixpanelService: MixpanelService,
        private deliverableViewService: DeliverableViewService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private reportService: ReportService,
        private routeService: RouterService,
        private deliverableInfoService: DeliverableInfoService,
        private userViewService: UserViewService,
        private insightService: InsightService,
        private filterService: FilterService
    ) {
        this.isConceptView = true;
        this.subscriptions = [];
        this.userViews = [];
    }

    ngOnInit(): void {
        const insightId = this.routeService.getQueryParam('insightId');
        const subscription = combineLatest([
            this.reportService.get(),
            this.userService.getUser()
        ]).subscribe(([report, user]) => {
            this.report = report;
            this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
            this.isInternalUser = user.isInternalUser;
            forkJoin([
                this.userViewService.fetchReportUserViewsFromAPI(report.id),
                this.insightService.getInsightFilterData<FinancialPotentialFilter>(report.id, insightId),
                this.financialPotentialService.loadDefaultFilter()
            ]).subscribe(([userViews, insightFilter, defaultViewFilters]) => {
                this.userViews = this.userViewService.setupUserViews(this.report.id, this.deliverableType, userViews, defaultViewFilters, insightFilter);
                const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
                this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));
                /**
                 * Add subscription to watch filter changes here so that s&w deliverable data can also be updated as per filter change.
                 * */
                this.subscriptions.push(combineLatest([
                    this.financialPotentialService.getFinancialPotentialFilter(),
                    this.financialPotentialService.getFinancialPotential()
                ]).subscribe(([filter, financialPotentialDeliverableView]) => {
                    this.filter = filter;
                    this.financialPotentialDeliverableView = financialPotentialDeliverableView;
                    this.isConceptView = this.financialPotentialService.isConceptsCompareFilter(this.filter.compare);
                    this.isSubgroupDatabasable = this.financialPotentialService.isSelectedSubgroupDatabasable(this.filter.subgroups);
                    const [charData, colHeaders] = this.chartDataService.getChartData(this.filter, this.financialPotentialDeliverableView);
                    this.dataSource = charData;
                    this.displayedColumns = colHeaders;
                    this.isAlcoholStudy = this.filter.datasets.length > 0;
                    this.disableBtn = this.isConceptSubgroupExists(this.filter);
                }));
            });
        });

        this.subscriptions.push(subscription);
    }

    /**
     * Method that is triggered when user view is changed. This will in turn update the filter model in the store.
     * */
    selectUserView(userView: UserView): void {
        this.filter = userView.filter as FinancialPotentialFilter;
        this.isConceptView = this.financialPotentialService.isConceptsCompareFilter(this.filter.compare);
        this.isSubgroupDatabasable = this.financialPotentialService.isSelectedSubgroupDatabasable(this.filter.subgroups);
        this.isAlcoholStudy = this.filter.datasets.length > 0;
        this.disableBtn = this.isConceptSubgroupExists(this.filter);
        this.filterService.update(userView.filter);
    }

    /**
     * Cleanup hook.
     *
     * @member FinancialPotentialComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * Returns label alphabet from index.
     *
     * @returns {string}
     * @memberof FinancialPotentialComponent
     */
    public getRowBadgeLabel(index): string {
        return this.stringHelper.alphabetSeries(index);
    }

    /**
     * Get the Array of Categories in the format
     * Example: "<primaryCategorySetName> + (<primaryCategorySetbaseSize> concepts)
     *
     * @returns string[]
     * @memberOf FinancialPotentialComponent
     */
    public getCategories(): Array<string> {
        const categories = [];
        if (this.dataSource) {
            this.dataSource.forEach(dataItem => {
                this.setCategoryData(categories, dataItem.primaryCompetitiveSet, dataItem.primaryCompetitiveSetBaseSize);
            });
        }
        return categories;
    }

    /**
     * Check whether we have lower ranks available for Concept Potential, Weighted Purchase Intent, Units, and Frequency in KMA file
     *
     * @returns true if all ranks are null for every item in dataSource
     * @memberOf FinancialPotentialComponent
     */
    public areRankScoresNull(): boolean {
        return this.dataSource.every(item =>
            item.frequencyRankScore === null && item.purchaseIntentRankScore === null && item.trialUnitsRankScore === null
            && item.conceptPotentialRankScore === null
        );
    }

    /**
     * set the category data
     * @param categories
     * @param primaryCompetitiveSet
     * @param primaryCompetitiveSetBaseSize
     * @private
     */
    private setCategoryData(categories, primaryCompetitiveSet: number, primaryCompetitiveSetBaseSize: number) {
        if (primaryCompetitiveSet) {
            const categoryDetails = `${primaryCompetitiveSet} (${primaryCompetitiveSetBaseSize} concepts)`;
            if (!categories.find(item => item === categoryDetails)) {
                categories.push(categoryDetails);
            }
        }
    }

    /**
     * Return the index position of the category based on the Name and size given in the Categories Array
     * @param cellData
     *
     * @returns number
     * @memberOf FinancialPotentialComponent
     */
    public matchCategoryWithConcept(cellData: any): number {
        const categories = this.getCategories();
        if (categories.length !== 0) {
            const categoryDetails = `${cellData.primaryCompetitiveSet} (${cellData.primaryCompetitiveSetBaseSize} concepts)`;
            return categories.indexOf(categoryDetails) + 1;
        }
    }

    /**
     * return the trialUnitsMaxvalue
     */
    public getTrialUnitsMaxValue() {
        return this.dataSource && (this.dataSource).length > 0 ? this.dataSource[0].trialUnitsBarChart.options.bar.domain.max : 5;
    }

    /**
     * return the frequency Amoung Favs Max value
     */
    public getFrequencyUnitsMaxValue() {
        return this.dataSource && (this.dataSource).length > 0 ? this.dataSource[0].frequencyBarChart.options.bar.domain.max : 20;
    }

    /**
     * toggle between headers and insight creation form.
     *
     */
    openInsightCreationForm() {
        this.isInsightEnable = true;
        this.deliverableData = {
            deliverable: {
                deliverableViewId: this.financialPotentialDeliverableView.id,
                filter: this.filter,
                insightHTML: this.deliverableInsightService.getInsightHTML()
            }
        };
    }

    /**
     * Close insight form
     */
    closeInsight() {
        this.isInsightEnable = false;
    }

    /**
     * capture screen layout and export as png.
     *
     */
    exportAsPNG() {
        this.mixpanelService.track(MixpanelLabel.finance, MixpanelEvent.exportAsPNG);
        this.displayProgressSpinner = true;
        this.exportPNGService.exportPNG();
        this.spinnerService.getSpinnerObs().subscribe(loading => this.displayProgressSpinner = loading);
    }

    /**
     * check concept count
     * */
    isConceptSubgroupExists(filter: FinancialPotentialFilter): boolean {
        const conceptCount = filter.concepts.filter(it => it.isSelected === true).length;
        const subgroupCount = filter.subgroups.filter(it => it.isSelected === true).length;
        return (conceptCount === 0 || subgroupCount === 0);
    }

    /**
     * open Import
     */
    public openImport() {
        this.isImport = true;
    }

    /**
     * Cancel Import
     */
    public cancelImport() {
        this.isImport = false;
    }

    /**
     * Apply Import
     */
    public applyImport() {
        this.isImport = false;
    }

    /**
     * Action that is triggered when the deliverable info is changed.
     *
     * @param deliverableInfo
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }

}
