<div rel="tooltip" matTooltipPosition="above" matTooltip=" {{ tooltipText }}" [matTooltipDisabled]="disable" *ngIf="isDropDownData; else noData" class="filter-wrapper dropdown-component">
    <ns-dropdown-menu [isDisable]="disable" [label]="newLabel ? (newLabel | truncateLabel: truncateLabelCount) : defaultLabel" [contentTitle]="dropDownData.contentTitle">
        <div class="dropdown-filter-menu">
            <ul class="dropdown-menu-list">
                <!-- List Items -->
                <div *ngIf="isListItems">
                    <li *ngFor="let item of dropDownData.data" (click)="select(dropDownData,item)" class="list-item">
                        {{item.label}}
                    </li>
                </div>

                <!-- Checkbox Items -->
                <div *ngIf="isCheckboxes && !dropDownData.groupSelect" (click)="$event.stopPropagation()">
                    <li class="list-item" *ngFor="let item of dropDownData.data">
                        <mat-checkbox color="primary" [(ngModel)]="item.selected" (ngModelChange)="select(dropDownData, item)">{{item.label}}</mat-checkbox>
                    </li>
                </div>

                <!-- Checkbox Group with Select All-->
                <div *ngIf="isCheckboxes && dropDownData.groupSelect" #checkboxgroup>
                    <li *ngIf="dropDownData.groupSelect" class="group-list-item">
                      <mat-checkbox
                            [checked]="isAllCompleted"
                            (change)="selectAllCheckboxes($event.checked)"
                            (click)="$event.stopPropagation()"
                            color="primary">
                            {{dropDownData.groupSelect}}
                      </mat-checkbox>
                    </li>
                    <ul [ngClass]="'dropdown-menu-sublist'">
                        <li class="list-item" *ngFor="let item of dropDownData.data" (click)="$event.stopPropagation()">
                            <mat-checkbox color="primary" [(ngModel)]="item.selected" (ngModelChange)="updateAllSelectCheckboxes()" >{{item.label}}</mat-checkbox>
                        </li>
                    </ul>
                </div>

                <!-- Radio Items -->
                <div *ngIf="isRadioButtons">
                    <mat-radio-group [value]="selectedRadioItem.value">
                        <li class="list-item" *ngFor="let item of dropDownData.data">
                            <mat-radio-button color="primary" (change)="select(dropDownData, item)" [value]="item.value">{{item.label}}</mat-radio-button>
                        </li>
                    </mat-radio-group>
                </div>
            </ul>
        </div>
    </ns-dropdown-menu>
</div>

<ng-template #noData>
    <p>{{ 'shared.dropdown.menu.no.data' | translate }}</p>
</ng-template>
