<ns-deliverable-configuration-header
        [headerText]="'Survey Questions'"
        (backBtnClickEvent)="returnBack()"
        (closeBtnClickEvent)="onCloseButtonClick()">
</ns-deliverable-configuration-header>
<div cdkDropList (cdkDropListDropped)="dropQuestion($event)">
    <div class="item-row-dragndrop" *ngFor="let surveyQuestionConfiguration of surveyQuestionConfigurations" cdkDrag>
        <div class="cdk-custom-placeholder" *cdkDragPlaceholder></div>
        <div class="item-row">
            <button mat-icon-button cdkDragHandle class="drag-n-drop-button">
                <span class="sif sif-drag-handler sif-12 sif-gray"></span>
            </button>
            <button mat-icon-button (click)="showAnswers(surveyQuestionConfiguration)" class="toggle-button">
                <span class="sif sif-12 expand-collapse sif-gray cursor-pointer" [ngClass]="expandedSurveyQuestionConfiguration.has(surveyQuestionConfiguration) ? 'sif-chevron-s':'sif-chevron-e'"></span>
            </button>
            <input matInput class="name-input" (change)="displayNameChange($event,surveyQuestionConfiguration)" [value]="surveyQuestionConfiguration.questionName" matTooltipPosition="above" [matTooltip]="surveyQuestionConfiguration.questionName"/>
            <button mat-icon-button class="visibility-button" (click)="toggleVisibility(surveyQuestionConfiguration)">
                <span class="sif sif-gray cursor-pointer" [ngClass]="surveyQuestionConfiguration.selected? 'sif-eye-enabled sif-12' : 'sif-eye-disabled sif-15'"></span>
            </button>
        </div>
        <ns-survey-question-answers-configuration
                *ngIf="expandedSurveyQuestionConfiguration.has(surveyQuestionConfiguration)"
                [surveyQuestionConfiguration]="surveyQuestionConfiguration"
                (configurationChangedEvent)="questionAnswerConfigurationChanged($event)">
        </ns-survey-question-answers-configuration>
    </div>
</div>
<div class="btn-group drop-button margin-top-1-point-5">
    <button small class="m-0 mat-button-secondary reset-to-default-button" mat-button color="secondary" [disabled]="!enableReset" (click)="resetToDefault()" mat-dialog-close>
        <span class=" sif sif-reset"></span> Reset To Default
    </button>
    <div class="save-slide-down">
        <button small mat-flat-button class="save-dropdown-btn" [disabled]="!enableSave" (click)="onSave()" [color]="!enableSave ? 'secondary' : 'primary'">
            SAVE
        </button>
        <button small mat-flat-button [matMenuTriggerFor]="menu" class="button-as-trigger" [color]="!enableSave ? 'secondary' : 'primary'" [disabled]="!enableSave">
            <span class="sif sif-chevron-s"></span>
        </button>
        <mat-menu #menu="matMenu" xPosition="before" class="save-close-menu-dropdown" #dropdownList>
            <button small mat-menu-item (click)="onSaveAndClose()"> SAVE AND CLOSE </button>
        </mat-menu>
    </div>
</div>