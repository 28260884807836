import { Concept } from '@platform/models/concept.model';
import { ScoreCardView } from '@platform/score-cards/score-card-view';
import { DeliverableType } from '@app/deliverables/deliverable-type.enum';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { DeliverableInsightService } from '@platform/services/deliverable-insight.service';
import { DeliverableInsight } from '@platform/deliverable-insight/deliverable-insight.model';
import { SpinnerService } from '@platform/services/spinner.service';
import { ExportPngService } from '@platform/services/export-png.service';
import { CorrelationsDeliverableView } from './models/correlations.model';
import { UserService } from '@platform/services/user.service';
import { CorrelationsService } from './services/correlation.service';
import { ViewMetaInfoService } from '@platform/services/view-meta-info.service';
import { CorrelationsFilter } from './models/filter.model';
import { CorrelationsMetaInfo } from './models/view-meta-info.model';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {ReportService} from '@platform/services/report.service';
import {UserView} from '@platform/models/user-view.model';
import {UserViewService} from '@platform/services/user-view.service';
import {RouterService} from '@platform/services/router.service';
import {Report} from '@platform/models/report.model';
import {InsightService} from '@platform/insights/insights.service';
import {FilterService} from '@platform/services/filter.service';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
/**
 * `<ns-correlations>` component builds correlations deliverable for both
 * concepts and subgroups deliverable views.
 *
 * @example
 * <ns-correlations></ns-correlations>
 *
 * @export
 * @class CorrelationsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ns-correlations',
  templateUrl: './correlations.component.html',
  styleUrls: ['./correlations.component.scss']
})

export class CorrelationsComponent implements OnInit, OnDestroy, ScoreCardView {

  /**
   * Report document
   */
  public report: Report;
  /**
   * Correlations deliverable view data.
   *
   * @type {CorrelationsDeliverableView}
   * @member CorrelationsComponent
   */
   public correlations: CorrelationsDeliverableView;
  /**
   * Subscription objects for cleanup.
   *
   * @type {Array<Subscription>}
   * @member CorrelationsComponent
   */
   public subscriptions: Array<Subscription>;

  /**
   * The deliverable insight data when creating insight.
   * @type {DeliverableInsight} deliverableData
   * @memberOf CorrelationsComponent
   */
  public deliverableData: DeliverableInsight;

  /**
   * Correlations deliverable view filter object.
   *
   * @type {CorrelationsFilter}
   * @member CorrelationsComponent
   */
   public filter: CorrelationsFilter;

   public deliverableType = DeliverableType.CORRELATIONS.type;

  /**
    * The CorrelationsDeliverableView.
    * @type {CorrelationsDeliverableView} correlationsDeliverableView
    * @memberOf CorrelationsComponent
  */
  public correlationsDeliverableView: CorrelationsDeliverableView;

  public deliverableViews: Array<DeliverableView>;
  /**
   * toggle insight btn
   * @type {Boolean} isInsightEnable
   * @memberOf CorrelationsComponent
   */
   public isInsightEnable = false;

   public isInternalUser: Boolean;

   /**
    * Meta info for Correlations
    *
    * @type {CorrelationsDeliverableView}
    * @memberOf CorrelationsComponent
    */
   public viewCorrelationsMetaInfo: CorrelationsMetaInfo;

   /**
    * Spinner.
    *
    * @type {Array<any>}
    * @member CorrelationsComponent
    */
   public displayProgressSpinner = false;

   public disableBtn: boolean;

   /**
    * ScoreCard Concept object for correlations.
    *
    * @type {Concept}
    */
    public scoreCardConcept: Concept;

    public deliverableInfos: Array<DeliverableInfo>;

    public userViews: Array<UserView>;

    public reload =  false;

   /**
    * Creates an instance of CorrelationsComponent and initialize
    * the component data.
    *
    * @constructor
    * @param {CorrelationsService} correlationsService
    * @param deliverableViewService
    * @param exportPNGService
    * @param viewMetaInfoService
    * @param spinnerService
    * @param deliverableInsightService
    * @param userService
    * @param routeService
    * @param userViewService
    * @param deliverableInfoService
    * @param filterService
    * @param reportService
    * @param insightService
    * @member CorrelationsComponent
    */
  constructor(private deliverableInsightService: DeliverableInsightService,
              private correlationsService: CorrelationsService,
              private deliverableViewService: DeliverableViewService,
              private exportPNGService: ExportPngService,
              private spinnerService: SpinnerService,
              private viewMetaInfoService: ViewMetaInfoService,
              private userService: UserService,
              private routeService: RouterService,
              private userViewService: UserViewService,
              private deliverableInfoService: DeliverableInfoService,
              private filterService: FilterService,
              private reportService: ReportService,
              private insightService: InsightService,
              private productDeliverableViewService: ProductDeliverableViewService) {
    this.subscriptions = [];
    this.userViews = [];
  }

  /**
   * Initialize the correlations component view.
   *
   * @member CorrelationsComponent
  */
  ngOnInit(): void {
      const subscription = this.loadComponentData();
      this.subscriptions.push(subscription);
      this.subscriptions.push(this.reportService.correlationConfigChanged$.subscribe((configChanged) => {
          if (configChanged) {
              this.productDeliverableViewService.clearCache();
              this.loadComponentData();
              this.reload = !this.reload;
          }
      }));
  }

    loadComponentData() {
        const insightId = this.routeService.getQueryParam('insightId');
        return combineLatest([
            this.reportService.get(),
            this.userService.getUser(),
            this.deliverableViewService.getDeliverableViews(DeliverableType.CORRELATIONS.type)
        ]).subscribe(([report, user, deliverableViews]) => {
            this.report = report;
            this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
            this.isInternalUser = user.isInternalUser;
            this.deliverableViews = deliverableViews;
            // load saved user views(filter) if they exist
            forkJoin([
                this.userViewService.fetchReportUserViewsFromAPI(this.report.id),
                this.insightService.getInsightFilterData<CorrelationsFilter>(report.id, insightId),
                this.correlationsService.loadDefaultFilter(null, this.deliverableViews)
            ]).subscribe(([userViews, insightFilter, defaultViewFilters]) => {
                this.userViews = this.userViewService.setupUserViews(this.report.id, this.deliverableType, userViews, defaultViewFilters, insightFilter);
                const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
                this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));
                combineLatest([
                    this.correlationsService.getCorrelationsFilter(),
                    this.viewMetaInfoService.get<CorrelationsMetaInfo>(this.deliverableType),
                    this.correlationsService.getCorrelations(),
                ]).subscribe(([filters, viewMetaInfo, correlation]) => {
                    this.isInternalUser = user.isInternalUser;
                    this.correlationsDeliverableView = correlation;
                    this.viewCorrelationsMetaInfo = viewMetaInfo;
                    this.filter = filters;
                    this.setDeliverableViewType();
                });
            });
        });
    }


  /**
   * Method that is triggered when user view is changed. This will in turn update the filter model in the store.
   * */
  selectUserView(userView: UserView): void {
      this.filter = userView.filter as CorrelationsFilter;
      this.disableBtn = this.isAnyKeyMeasureSelected(this.filter);
      this.filterService.update(userView.filter);
  }

  /**
   * toggle between headers and insight creation form.
   *
   */
   openInsightCreationForm() {
    this.isInsightEnable = true;
    const compareFilter = this.filter.deliverableViewType
    const insightHTMLData = this.deliverableInsightService.getInsightHTML();
    this.deliverableData = {
      deliverable: {
      metaInfo: this.viewCorrelationsMetaInfo,
      deliverableViewId: this.deliverableViews.length > 0 ? this.deliverableViews[0].id : this.correlationsDeliverableView.id,
      filter: this.filter,
      insightHTML: insightHTMLData
      }
    };
      if(compareFilter === 'quadMap'){
        this.deliverableData['selectors'] = ['#svg-container']
    }
  }

  /**
   * Close insight form
   */
   closeInsight() {
    this.isInsightEnable = false;
  }

  setViews(event) {
    console.log('setViews', event);
  }

  update(event) {
    console.log('update', event);
  }

/**
   * set deliverable view type to load view
   *
   */

  setDeliverableViewType() {
    if (Object.keys(this.correlationsDeliverableView.metaInfo.dataTable).length !== 0  && Object.keys(this.correlationsDeliverableView.metaInfo.quadMap).length !== 0) {
      this.deliverableType = this.filter.deliverableViewType
    }
    else if (Object.keys(this.correlationsDeliverableView.metaInfo.dataTable).length === 0)
     this.deliverableType = 'quadMap';
    else
     this.deliverableType = 'dataTable';
  }

  /**
   * capture screen layout and export as png.
   *
   */
  exportAsPNG() {
    this.displayProgressSpinner = true;
    this.exportPNGService.exportPNG([], true);
    this.spinnerService.getSpinnerObs().subscribe((loading) => this.displayProgressSpinner = loading);
  }

  /**
   * Cleanup hook.
   *
   * @member CorrelationsComponent
   */
   ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * check key measures count
   * */
  isAnyKeyMeasureSelected(filter: CorrelationsFilter): boolean {
    const keyMeasuresCount = filter.show.keyMeasures[this.correlationsService.viewName].filter(it => it.isSelected === true).length;
    return (keyMeasuresCount === 0);
  }

    /**
     * Action that is triggered when the deliverable info is changed.
     *
     * @param deliverableInfo
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }
}
