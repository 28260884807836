import {ReachAnalysisDeliverableView} from '@app/deliverables/reach-analysis/models/reach-anaylsis.model';
import {Concept} from '@platform/models/concept.model';
import {ScoreCardView} from '@platform/score-cards/score-card-view';
import {DeliverableType} from "@app/deliverables/deliverable-type.enum";
import {ReachAnalysisMetaInfo} from './models/view-meta-info.model';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, forkJoin, Subscription} from 'rxjs';
import {UserService} from '@platform/services/user.service';
import {ReachAnalysisService} from '@app/deliverables/reach-analysis/services/reach-analysis.service';
import {ConceptFilterItem, FilterItem, ReachAnalysisFilter} from '@app/deliverables/reach-analysis/models/filter.model';
import {FilterService} from '@platform/services/filter.service';
import {ExportPngService} from '@platform/services/export-png.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {skipWhile, take} from 'rxjs/operators';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import { MixpanelService } from '@platform/services/mixpanel.service';
import {MixpanelLabel, MixpanelEvent, MixpanelReach} from '@src/assets/utils/mixpanel-enum';
import {ReportService} from "@platform/services/report.service";
import {Report} from "@platform/models/report.model";
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {SubgroupService} from '@platform/services/subgroup.service';
import {Subgroup} from '@platform/models/subgroup.model';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {UserView} from '@platform/models/user-view.model';
import {RouterService} from '@platform/services/router.service';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {UserViewService} from '@platform/services/user-view.service';
import {InsightService} from '@platform/insights/insights.service';
import {FinancialPotentialFilter} from '@app/deliverables/financial-potential/models/filter.model';

@Component({
  selector: 'ns-reach-analysis',
  templateUrl: './reach-analysis.component.html',
  styleUrls: ['./reach-analysis.component.scss']
})
/**
 * Reach analysis is different from other deliverables, here we need to load deliverable view first and then will populate filters
 * according to that view data. so in filters component default filters will load deliverable view data information
 * */
export class ReachAnalysisComponent implements OnInit, OnDestroy, ScoreCardView {
  /**
   * Survey Question List
   * @memberOf ReachAnalysisComponent
   */
  public questionsList: Array<any>;

  /**
   * Selected Question String
   * @memberOf ReachAnalysisComponent
   */
  public questionSelect: any;

  /**
   * check insights enable/disable.
   *
   * @type {number[]}
   * @memberOf ReachAnalysisComponent
   */
  public isInsightEnable = false;

  /**
   * Subscription objects for cleanup.
   *
   * @type {Array<Subscription>}
   * @memberof ReachAnalysisComponent
   */
  public subscriptions: Array<Subscription>;
  /**
   * Attributes deliverable view filter object.
   *
   * @type {AttributesFilter}
   * @member ReachAnalysisComponent
   */
  public filter: ReachAnalysisFilter;

  /**
   * Spinner.
   *
   * @type {Array<any>}
   * @memberOf ReachAnalysisComponent
   */
  public displayProgressSpinner = false;
  public questionViewDisplaySpinner: boolean = false;
  public selectedConceptInFilter: number;
  public selectedSubgroupInFilter: number;
  public filterChangeDisplaySpinner = false;
  public noDataMode:boolean = false;
  /**
   * internal user check`
   * component.
   *
   * @type {number[]}
   * @memberOf ReachAnalysisComponent
   */
  public isInternalUser: Boolean;

  public compareView: string;

  segmentName: string;
  countryName: string;

  public reachAnalysis: ReachAnalysisDeliverableView;

  public disableBtn: boolean;

  public report: Report;

  public deliverableType = DeliverableType.REACH_ANALYSIS.type;

  /**
   * ScoreCard Concept object for reach analysis.
   *
   * @type {Concept}
   */
  public scoreCardConcept: Concept;
  public deliverableData: DeliverableInsight;
  public viewReachMetaInfo: ReachAnalysisMetaInfo;
  public deliverableViews: Array<DeliverableView>;
  public subgroups: Array<Subgroup>;
  public deliverableInfos: Array<DeliverableInfo>;
  public userViews: Array<UserView>;


  constructor(private userService: UserService,
              private filterService: FilterService,
              private reachAnalysisService: ReachAnalysisService,
              private exportPNGService: ExportPngService,
              private viewMetaInfoService: ViewMetaInfoService,
              private spinnerService: SpinnerService,
              private deliverableInsightService: DeliverableInsightService,
              private mixpanelService: MixpanelService,
              private reportService: ReportService,
              private deliverableViewService: DeliverableViewService,
              private subgroupService: SubgroupService,
              private routeService: RouterService,
              private deliverableInfoService: DeliverableInfoService,
              private userViewService: UserViewService,
              private insightService: InsightService
  ) {
    this.subscriptions = [];
    this.questionsList = [];
    this.deliverableViews = [];
    this.subgroups = [];
    this.userViews = [] ;
  }

  ngOnInit(): void {
      const insightId = this.routeService.getQueryParam('insightId');
      const deliverableType = DeliverableType.REACH_ANALYSIS.type;
      const subscription =  combineLatest([
          this.reportService.get(),
          this.userService.getUser(),
          this.deliverableViewService.getDeliverableViews(DeliverableType.REACH_ANALYSIS.type)
      ]).subscribe(([report, user, deliverableViews]) => {
          this.report = report;
          this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
          this.isInternalUser = user.isInternalUser;
          this.deliverableViews = deliverableViews.filter(it => it.metaInfo.isViewEnabled);
          forkJoin([
              this.userViewService.fetchReportUserViewsFromAPI(report.id),
              this.insightService.getInsightFilterData<ReachAnalysisFilter>(report.id, insightId),
              this.reachAnalysisService.loadDefaultFilter(this.deliverableViews)
          ]).subscribe(([userViews,  insightFilter, defaultViewFilters]) => {
              this.userViews = this.userViewService.setupUserViews(this.report.id, deliverableType, userViews, defaultViewFilters, insightFilter);
              const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
              this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));
              /**
               * Add subscription to watch filter changes here so that s&w deliverable data can also be updated as per filter change.
               * */
              this.subscriptions.push(combineLatest([
                  this.reachAnalysisService.getReachAnalysisFilter(),
                  this.reachAnalysisService.getReachAnalysis(),
               this.subgroupService.getReportSubgroups(),
                  this.viewMetaInfoService.get<ReachAnalysisMetaInfo>(deliverableType)
              ]).subscribe(([filters, reachAnalysis, subgroups, viewMetaInfo]) => {
                  this.viewReachMetaInfo = viewMetaInfo;
                  this.filter = filters;
                  this.displayProgressSpinner = false;
                  this.isInternalUser = user.isInternalUser;
                  this.report = report;
                  this.compareView = filters.compare.find(it => it.isSelected === true).id;
                  this.segmentName = filters.subgroups.find(it => it.isSelected === true).name;
                  this.countryName = filters.countries[0];
                  this.questionsList = filters.questions;
                  this.reachAnalysis = reachAnalysis;
                  this.questionSelect = filters.questions.find(item => item.isSelected).id;
                  this.disableBtn = this.isConceptSubgroupExists(filters);
                  // display spinner related
                  this.questionViewDisplaySpinner = this.reachAnalysis.viewName !== this.questionSelect;
                  this.selectedSubgroupInFilter = filters.subgroups.find(it => it.isSelected === true).id;
                  this.selectedConceptInFilter = filters.concepts.find(it => it.isSelected === true).id;
                  this.filterChangeDisplaySpinner = this.getDisplayProgressBarStatus();
                  this.subgroups = subgroups;
              }));
          });
      });
              this.subscriptions.push(subscription);
  }

    /**
     * Method that is triggered when user view is changed. This will in turn update the filter model in the store.
     * */
    selectUserView(userView: UserView): void {
        this.filter = userView.filter as ReachAnalysisFilter;
        this.disableBtn = this.isConceptSubgroupExists(this.filter);
        this.questionSelect =  this.filter.questions.find(item => item.isSelected).id;
        this.compareView = this.filter.compare.find(it => it.isSelected === true).id;
        this.segmentName = this.filter.subgroups.find(it => it.isSelected === true).name;
        this.selectedSubgroupInFilter =  this.filter.subgroups.find(it => it.isSelected === true).id;
        this.selectedConceptInFilter =  this.filter.concepts.find(it => it.isSelected === true).id;
        this.filterService.update(userView.filter);
    }


    /**
   * The Request from backend gives the Combinations data in reachAnalysis but
   * the spinner has to appear when the combinations are yet to emit or when combination data and Filter data do not match.
   *
   * NOTE: the spinner on direct Get request delay was tried but it seems like a spinner has to be applied on Platform Filter service which
   * is out of scope for now.
   */
  getDisplayProgressBarStatus() {
    let status = false;
    if(this.reachAnalysis.combinations.length > 0){
      //Combinations Data exists, check if Filter Data and Combinations Data DO NOT align to show the spinner
      status = (this.selectedSubgroupInFilter !== this.reachAnalysis.combinations[0].subgroupId) || (this.selectedConceptInFilter !== this.reachAnalysis.combinations[0].conceptId);
      this.noDataMode = false;
    } else if(this.reachAnalysis.combinations.length === 0 && this.noDataMode){
      // no Combinations and no Data but check if Navigation is between two NO Data filters
      let conceptsList = this.reachAnalysis.concepts.map(it => it.conceptId)
      status = this.reachAnalysis.subgroups.find(it => it.subgroupId === this.selectedSubgroupInFilter).included && conceptsList.includes(this.selectedConceptInFilter);
    } else {
      //no Combinations means no Data
      this.noDataMode = true;
    }
    return status;
  }


  getViewLabel(questionId) {
    return this.deliverableViews && this.deliverableViews.length && this.deliverableViews.find(it => it.viewName.toString() === questionId.toString()) ? this.deliverableViews.find(it => it.viewName.toString() === questionId.toString()).metaInfo.viewLabel : '';
  }

  /**
   * check concept count
   * */
  isConceptSubgroupExists(filter: ReachAnalysisFilter): boolean {
    const conceptCount = filter.concepts.filter(it => it.isSelected === true).length;
    const subgroupCount = filter.subgroups.filter(it => it.isSelected === true).length;
    return (conceptCount === 0 || subgroupCount === 0);
  }

  /**
   * Loads questions dropdown.
   *
   * @memberOf ReachAnalysisComponent
   */
  selectQuestion(selectedQuestionId: any) {
    this.mixpanelService.track(MixpanelLabel.reach, MixpanelReach.changeChart);
    const views = this.reachAnalysisService.getViews(this.report);
    let questionView = this.deliverableViews.find(it => it.viewName == selectedQuestionId);
    let questionData = questionView.metaInfo.questiondata;
    const selectedSubgroup = questionData.subgroups.filter(it => it.included)[0];
    let subgroupPosition = 0;
    let varietiesLength;
      this.filterService.update({
          ...this.filter,
          basesLabel: questionView.metaInfo.basesLabel,
          questions: this.filter.questions.map((question) => {
              return {
                  ...question,
                  isSelected: question.id === selectedQuestionId
              };
          }),
          concepts: this.filter.concepts.map((concept) => {
              let selectedConceptId = questionData.concepts[0].conceptId;
              let filteredVarieties = [];
              let questionConcept = questionData.concepts.find(it => it.conceptId == concept.id);
              if (questionConcept) {
                  let varieties = questionConcept.varieties;
                  let updateVarieties = Object.assign([{}], varieties);
                  filteredVarieties = this.reachAnalysisService.getVarietiesFilters(updateVarieties);
                  varietiesLength = filteredVarieties.length;
              }
              return {
                  ...concept,
                  isSelected: concept.id === selectedConceptId,
                  varieties: filteredVarieties
              };
          }),
          subgroups: questionData.subgroups.filter(it => it.included).map((subgroup) => {
              const subgroupDetails = this.subgroups.find(it => it.segmentId === subgroup.subgroupId);
              return {
                  ...{name: subgroupDetails.name, id: subgroup.subgroupId, position: subgroupDetails.position},
                  isSelected: selectedSubgroup.subgroupId === subgroup.subgroupId
              };
          }),
          show: {
              ...this.filter.show,
              combinationSets: this.getCombinationSets(varietiesLength)
          }
      });
  }

  getCombinationSets(totalVarieties): Array<FilterItem>{
    const sets: Array<FilterItem> = [];
    for (let i = 1; i <= totalVarieties; i++) {
      sets.push({
        name: (i).toString(),
        id: i,
        isSelected: false,
        position: i
      });
    }
    sets[0].isSelected = true;
    return sets;
  }

  /**
   * Cleanup hook.
   *
   * @memberOf ReachAnalysisComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * capture screen layout and export as png.
   *
   */
  exportAsPNG() {
    this.mixpanelService.track(MixpanelLabel.reach, MixpanelEvent.exportAsPNG);
    this.displayProgressSpinner = true;
    this.exportPNGService.exportPNG();
    this.spinnerService.getSpinnerObs().subscribe(loading => this.displayProgressSpinner = loading);
  }

  /**
   * toggle between headers and insight creation form.
   *
   */
  openInsightCreationForm() {
    this.isInsightEnable = true;
    this.deliverableData = {
      deliverable: {
        deliverableViewId: this.reachAnalysis.id,
        filter: this.filter,
        metaInfo: this.viewReachMetaInfo,
        insightHTML: this.deliverableInsightService.getInsightHTML()
      }
    };
  }

  /**
   * Close insight form
   */
  closeInsight() {
    this.isInsightEnable = false;
  }

    /**
     * Action that is triggered when the deliverable info is changed.
     *
     * @param deliverableInfo
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }

}
