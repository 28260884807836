<div class="deliverable-container correlations-content">
  <ng-container *ngIf="!scoreCardConcept">
    <div class="sticky-header-container header-filter-container" [ngClass]="{'hide': isInsightEnable}">
        <ns-deliverable-nav-header [deliverableInfos]="deliverableInfos" (deliverableChanged)="onDeliverableChange($event)">
            <ns-kebab-menu class="kabab-menu">
              <ul class="kebab-menu-list">
                <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
              </ul>
            </ns-kebab-menu>
            <span rel="tooltip" matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}" *ngIf="isInternalUser" [ngClass]="{'disable-insight-btn': disableBtn}" class="insight-icon sif sif-insight" (click)="openInsightCreationForm()">
            </span>
            <span saveUserView>
                <ns-save-user-view class="save-views"
                                   [(views)]="userViews"
                                   [filter]="filter"
                                   [reportId]="report?.id"
                                   [deliverableType]="deliverableType"
                                   (viewsChange)="userViews=$event"
                                   (selectUserView)="selectUserView($event)"></ns-save-user-view>
            </span>
        </ns-deliverable-nav-header>
    </div>
    <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableData" (close)="closeInsight()"></ns-deliverable-insight>
  </ng-container>

    <div id="deliverable-container">
      <div class="filters-sticky-container" [ngClass]="{'hide': isInsightEnable}">
        <!--Correlations filter-->
        <ns-correlations-filter [reload] = "reload" [concept]="scoreCardConcept"></ns-correlations-filter>
      </div>
      <div id="correlations-content" class="correlations-content deliverable-insight-correlations insight-html-root">
        <ng-container *ngIf="filter">
          <ng-container [ngSwitch]="deliverableType">
            <ns-data-table *ngSwitchDefault></ns-data-table>
            <ns-quad-map *ngSwitchCase="'quadMap'"></ns-quad-map>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
