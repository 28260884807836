<div class="deliverable-container tga-content">
  <ng-container *ngIf="!scoreCardConcept">
    <div class="sticky-header-container header-filter-container" [ngClass]="{'hide': isInsightEnable}">
      <div class="sticky-header-container">
        <!--Swipe -->
        <ns-deliverables-header>
          <ns-kebab-menu>
            <ul class="kebab-menu-list">
              <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
            </ul>
          </ns-kebab-menu>
          <span rel="tooltip" matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}" *ngIf="isInternalUser" [ngClass]="{'disable-insight-btn': disableBtn}" class="insight-icon sif sif-insight" (click)="openInsightCreationForm()"></span>
        </ns-deliverables-header>
      </div>
    </div>
    <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableData" (close)="closeInsight()"></ns-deliverable-insight>
  </ng-container>


  <div id="deliverable-container">
    <div class="filters-sticky-container" [ngClass]="{'hide': isInsightEnable}">
      <ns-tga-filter [concept]="scoreCardConcept"></ns-tga-filter>
    </div>
    <div *ngIf="previousAnalysis" class="insight-html-root">
      <ns-target-group-old></ns-target-group-old>
    </div>
    <div *ngIf="!previousAnalysis" class="insight-html-root">
      <ns-target-group-new></ns-target-group-new>
    </div>
  </div>
</div>
<ns-progress-spinner class="example-margin" [display]="displayProgressSpinner">
</ns-progress-spinner>
