<div class="deliverable-container interactions-content">
  <div class="sticky-header-container header-filter-container" [ngClass]="{ 'hide': isInsightEnable }">
      <ns-deliverable-nav-header [deliverableInfos]="deliverableInfos" (deliverableChanged)="onDeliverableChange($event)">
          <ns-kebab-menu class="kabab-menu">
          <ul class="kebab-menu-list">
            <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
          </ul>
        </ns-kebab-menu>
          <span *ngIf="isInternalUser" [ngClass]="{'disable-insight-btn': disableBtn}" rel="tooltip" matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}" class="insight-icon sif sif-insight" (click)="openInsightCreationForm()"></span>
          <span saveUserView>
                <ns-save-user-view class="save-views"
                                   [(views)]="userViews"
                                   [filter]="filter"
                                   [deliverableType]="deliverableType"
                                   (viewsChange)="userViews=$event"
                                   (selectUserView)="selectUserView($event)"
                                   [reportId]="report.id">

                </ns-save-user-view>
            </span>
      </ns-deliverable-nav-header>
  </div>
  <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableData" (close)="closeInsight()">
  </ns-deliverable-insight>

  <div id="deliverable-container">
    <div class="filters-sticky-container" [ngClass]="{ 'hide': isInsightEnable }">
      <!--interactions filter-->
      <ns-interactions-filter></ns-interactions-filter>
    </div>
    <div class="table-wrapper deliverable-insight-interactions insight-html-root">
      <mat-table matSortStart="asc" matSort [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container *ngFor="let colHeader of colHeaders;let i = index;trackBy: trackItem"
          matColumnDef="{{ colHeader.name }}">
          <ng-container *ngIf="i === 0">
            <mat-header-cell class="statement-header" *matHeaderCellDef>
            </mat-header-cell>
            <mat-cell class="statement-header" *matCellDef="let element">
              <span class="segment-name">{{ element.competitor }}</span>
            </mat-cell>
          </ng-container>
          <ng-container *ngIf="i !== 0">
            <mat-header-cell rel="tooltip" matTooltipPosition="above" matTooltip = "{{this.getSortOrder(colHeader.name)}}" #columnHeader class="statement-header" *matHeaderCellDef mat-sort-header
              (click)="onClickHeader($event,colHeader.id,colHeader.name)">
              <span class="concept-header">{{ colHeader.name }}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ns-chart-horizontal-bar *ngIf="displayBarchart" [barChart]="element[colHeader.name]?.barChart">
              </ns-chart-horizontal-bar>
            </mat-cell>
          </ng-container>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns; let i = index">
        </mat-row>
      </mat-table>
    </div>
    <div class="col-12 legend-container">
      <div class="section padding-2 text-center footer-legend-text">
        <p><small>{{'restage.deliverable.interactions.footer.text1' | translate}}</small> </p>
        <p><small>{{'restage.deliverable.interactions.footer.text2' | translate}}</small> </p>
      </div>
    </div>
  </div>
</div>
<ns-progress-spinner class="example-margin" [display]="displayProgressSpinner">
</ns-progress-spinner>
