<h1 class="fp-title title">Financial Potential</h1>

<div class="row" *ngIf="showPurchaseIntent">
  <div class="col-1">Top 2 Box PI</div>
  <div class="col-2">{{piTop2}}%</div>
  <div class="col-3">{{piQuintileRank}}</div>
</div>
<div class="clear"></div>
<div class="row" *ngIf="showUnits">
  <div class="col-1">Unit</div>
  <div class="col-2">{{trialUnitsAmongFavs}}</div>
  <div class="col-3">{{trialUnitsRankScore}}</div>
</div>
<div class="clear"></div>
<div class="row" *ngIf="showFrequency">
  <div class="col-1">Frequency</div>
  <div class="col-2">{{frequencyAmongFavs}}</div>
  <div class="col-3">{{frequencyRankScore}}</div>
</div>
<div class="clear"></div>

