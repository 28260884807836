<div class="deliverable-container">
  <ng-container *ngIf="!scoreCardConcept">
    <div class="sticky-header-container header-filter-container" [ngClass]="{'hide': isInsightEnable}">
      <div class="sticky-header-container">
        <!--Swipe -->
        <ns-deliverables-header>
          <ns-kebab-menu>
            <ul class="kebab-menu-list">
              <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
            </ul>
          </ns-kebab-menu>
          <span rel="tooltip" matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}" *ngIf="isInternalUser" [ngClass]="{'disable-insight-btn': disableBtn}" class="insight-icon sif sif-insight" (click)="openInsightCreationForm()"></span>
        </ns-deliverables-header>
      </div>
    </div>
    <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableData" (close)="closeInsight()"></ns-deliverable-insight>
  </ng-container>


  <div class="image-view-container carousel-container" id="deliverable-container">
    <div class="sticky-swipe-table-container carousel-panel">
<!--      <div class="pagination-dots">-->
<!--        <ul>-->
<!--          <li *ngFor="let item of imageFiles; let i = index"><span [ngClass]="(i == currentPage) ? 'active': 'not'" class="dots"></span></li>-->
<!--        </ul>-->
<!--      </div>-->
      <mat-paginator (page)="pageChanged($event)" [pageIndex]="currentPage" #imageViewPagination class="pagination" [hidePageSize]="true" [pageSize]="1"></mat-paginator>
    </div>
    <div class="insight-html-root" id="image-view" *ngIf="imageFiles">
        <div><img crossorigin="use-credentials" class="insight-image" [src]="imageFiles[currentPage].name" /></div>
    </div>
  </div>
</div>
<ns-progress-spinner class="example-margin" [display]="displayProgressSpinner"></ns-progress-spinner>
