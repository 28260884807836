import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Concept} from '@platform/models/concept.model';
import {ConceptService} from '@platform/services/concept.service';
import {ReportService} from '@platform/services/report.service';
import {combineLatest, Subscription} from 'rxjs';
import {NodeToggleEvent} from '@app/deliverables/activation-profile/header/header-section/header-section.component';
import {ActivationProfileService} from '@app/deliverables/activation-profile/services/activation-profile.service';
import {UserService} from '@platform/services/user.service';
import {ExportPngService} from '@platform/services/export-png.service';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {ActivationProfileMetaInfo} from '@app/deliverables/activation-profile/models/view-meta-info.model';
import {skipWhile} from 'rxjs/operators';
import {SubgroupService} from '@platform/services/subgroup.service';
import {
    ActivationProfileDeliverableView,
    MeasureDescription,
    Measures
} from '@app/deliverables/activation-profile/models/activationProfile.model';
import {Subgroup} from '@platform/models/subgroup.model';
import {TreeNodeData} from '@app/deliverables/activation-profile/models/tree-node-data.interface';
import {ScoreCardView} from '@platform/score-cards/score-card-view';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent, MixpanelLabel} from '@src/assets/utils/mixpanel-enum';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';

@Component({
    selector: 'activation-profile',
    templateUrl: './activation-profile.component.html',
    styleUrls: ['./activation-profile.component.scss']
})
export class ActivationProfileComponent implements OnInit, OnDestroy, ScoreCardView {

    /**
     * toggle insight btn
     * @type {Boolean} isInsightEnable
     * @memberOf ActivationProfileComponent
     */
    public isInsightEnable = false;

    public tooltipForConceptNavigation = 'Concept';
    /**
     * Array of subscriptions for cleanup.
     *
     * @property
     * @private
     * @type {Array<Subscription>}
     * @memberof FilterComponent
     */
    private subscriptions: Array<Subscription>;
    public concepts: Concept[];
    public activationProfile: any;
    public headerList: Array<string>;
    public basesLabel: string;
    public measures: Array<Measures>;
    public measureLength: number;
    public isInternalUser: Boolean;
    public disableBtn: boolean;
    public locale: Array<string>;
    public columnWidths: number[];
    public displayedConcepts: Concept[];
    public deliverableViews: Array<DeliverableView>;

    /**
     * Spinner.
     *
     * @type {Array<any>}
     * @member AttributesComponent
     */
    public displayProgressSpinner = false;

    /**
     * The deliverable insight data when creating insight.
     * @type {DeliverableInsight} deliverableData
     * @memberOf ActivationProfileComponent
     */
    public deliverableData: DeliverableInsight;

    /**
     * Meta info for Attributes
     *
     * @type {AttributesDeliverableView}
     * @memberOf ActivationProfileComponent
     */
    public viewActivationProfileMetaInfo: ActivationProfileMetaInfo;

    /**
     * Sets concept object for activation profile.
     *
     * @type {Concept}
     */
    public scoreCardConcept: Concept;

    constructor(
        private reportService: ReportService,
        private subgroupService: SubgroupService,
        private conceptService: ConceptService,
        private activationProfileService: ActivationProfileService,
        private userService: UserService,
        private exportPNGService: ExportPngService,
        private viewMetaInfoService: ViewMetaInfoService,
        private spinnerService: SpinnerService,
        private deliverableInsightService: DeliverableInsightService,
        private mixpanelService: MixpanelService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private deliverableViewService: DeliverableViewService
    ) {
        this.subscriptions = [];
    }

    ngOnInit(): void {
        const deliverableType = DeliverableType.ACTIVATION_PROFILE.type;
        this.viewMetaInfoService.update({deliverableType}, deliverableType);
        const user$ = this.userService.getUser();
        const viewMetaInfo$ = this.viewMetaInfoService.get<ActivationProfileMetaInfo>(deliverableType);
        const report$ = this.reportService.get();
        const concepts$ = this.conceptService.getReportDeliverableConcepts(deliverableType);
        const subgroups$ = this.subgroupService.getReportSubgroups();
        const initialSubscription = combineLatest([report$]).subscribe(
            ([report]) => {
                // get the report and the productDeliverableView observable.
                this.locale = report.locales;
                const productDeliverableView$ = this.productDeliverableViewService.findByTypeAndName(report.id, deliverableType, 'concept');
                const deliverableViews$ = this.deliverableViewService.getDeliverableViews(deliverableType);
                // Subscribe to the remaining observables now that deliverableView$ exists.
                const secondSubscription = combineLatest([concepts$, viewMetaInfo$, user$, subgroups$, productDeliverableView$, deliverableViews$]).pipe(
                    skipWhile(([concept, viewMetaInfo, user, subgroups, productDeliverableView, deliverableViews]) =>
                        viewMetaInfo == null)).subscribe(
                    ([concept, viewMetaInfo, user, subgroups, productDeliverableView, deliverableViews]) => {
                        this.deliverableViews = deliverableViews;
                        this.activationProfile = productDeliverableView[0];
                        this.measures = this.getFilteredData(subgroups, this.activationProfile);
                        this.measureLength = this.measures.length;
                        this.concepts = this.getFilteredConcept(concept);
                        this.basesLabel = report.projectType;
                        this.viewActivationProfileMetaInfo = viewMetaInfo;
                        this.headerList = this.getHeadersList();
                        this.columnWidths = this.getColumnWidths(this.concepts);
                        this.isInternalUser = user.isInternalUser;

                    });
                this.subscriptions.push(secondSubscription);
            });
        this.subscriptions.push(initialSubscription);
        this.activationProfileService.loadDefaultFilter();
    }

    /**
     * Get profile List for selected concept.
     *
     * @memberof ActivationProfileComponent
     */
    getProfileList(concept: Concept): MeasureDescription {
        return this.measures.filter(it => it.conceptId === concept.exerciseConceptId)[0]?.measureDescriptions;
    }

    /**
     * Filtered concept as per data in csv.
     *
     * @memberof ActivationProfileComponent
     */
    getFilteredConcept(concepts: Array<Concept>): Array<Concept> {
        concepts = this.scoreCardConcept ? [this.scoreCardConcept] : concepts;
        return concepts.filter(concept =>
            this.measures.filter(it => it.conceptId === concept.exerciseConceptId)[0]?.measureDescriptions);
    }

    /**
     * Filtered data with first db group.
     *
     * @memberof ActivationProfileComponent
     */
    getFilteredData(subgroups: Array<Subgroup>, activationProfile: ActivationProfileDeliverableView): Array<Measures> {
        return activationProfile.measures.filter(measure => {
            const filteredSubgroup = subgroups.filter(subgroup => subgroup.isDatabaseable);
            const selectedSegment = filteredSubgroup ? filteredSubgroup[0].segmentId : '';
            return measure.segmentId === selectedSegment;
        });
    }

    /**
     * Header List.
     *
     * @memberof ActivationProfileComponent
     */
    getHeadersList(): Array<string> {
        const headerList = [];
        this.measures.forEach(element => {
            for (const [key, value] of Object.entries(element.measureDescriptions)) {
                if (headerList.indexOf(key) === -1) {
                    headerList.push(key);
                }
            }
        });
        return headerList;
    }

    /**
     * Cleanup hook.
     *
     * @memberof ActivationProfileComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * set node expand/collapse state.
     */
    onNodeToggle(event: NodeToggleEvent) {
        this.mixpanelService.track(MixpanelLabel.activation, `${event.isExpanded ? MixpanelEvent.expandRows : MixpanelEvent.collapseRows}`);
        this.setExpansionSavedView(event.node, event.isExpanded);
    }

    /**
     * set expansion for saved views.
     */
    setExpansionSavedView(node: TreeNodeData, value: boolean) {
        const deliverableType = DeliverableType.ACTIVATION_PROFILE.type;
        let viewInfo: ActivationProfileMetaInfo;
        viewInfo = this.viewActivationProfileMetaInfo || {deliverableType};
        // viewInfo.deliverableType = deliverableType;
        viewInfo = JSON.parse(JSON.stringify(viewInfo));
        viewInfo[node.key] = value;
        const childNode = node.children.find(it => it.type === 'Parent');
        if (childNode && !value) {
            if (viewInfo[childNode.key]) {
                viewInfo[childNode.key] = value;
            }
        }
        this.viewMetaInfoService.update(viewInfo, deliverableType);
    }

    /**
     * capture screen layout and export as png.
     *
     */
    exportAsPNG() {
        this.mixpanelService.track(MixpanelLabel.activation, MixpanelEvent.exportAsPNG);
        this.displayProgressSpinner = true;
        this.exportPNGService.exportPNG([], true);
        this.spinnerService.getSpinnerObs().subscribe(loading => this.displayProgressSpinner = loading);
    }

    /**
     * toggle between headers and insight creation form.
     *
     */
    openInsightCreationForm() {
        this.isInsightEnable = true;
        const deliverableView = this.deliverableViews.find(it => it.productViewId === this.activationProfile.id);
        const insightHTMLData = this.deliverableInsightService.getInsightHTML();
        this.deliverableData = {
            deliverable: {
                deliverableViewId: deliverableView.id,
                metaInfo: this.viewActivationProfileMetaInfo,
                insightHTML: insightHTMLData,
                filter: {deliverableType: DeliverableType.ACTIVATION_PROFILE.type, deliverableViewType: 'concept'}
            }
        };
    }

    /**
     * Close insight form
     */
    closeInsight() {
        this.isInsightEnable = false;
    }

    /**
     * Set Views
     * @param evt { String }
     */
    setViews(evt: any) {
        /**
         * ToDo:
         * Create a data model for views which has all details (sorting, highlights, filters, Insights ... )
         * Store the view in the DB
         * Create a Default View (set to defaults)
         * Set the view data on load
         */
        console.log(evt);
    }

    getToggledNode(event: string) {
        let node;
        if (event === 'concept') {
            node = this.activationProfileService.concept;
        }
        const isExpanded = this.activationProfileService.treeControl.isExpanded(node[0]);
        this.setExpansionSavedView(node[0], !isExpanded);
    }

    /**
     * get the column width's of visibility columns
     * @param concepts
     * @private
     */
    private getColumnWidths(concepts: Concept[]): number[] {
        const columnWidths = Array(concepts.length).fill(300);
        columnWidths.splice(0, 0, 302);
        return columnWidths;
    }

    /**
     * updates the displayed concepts based on the visibility.
     * If the concept has a position, it is added to visible concepts.
     * @param visibleColumns
     */
    updateConceptCardVisibility(visibleColumns: number[]) {
        const visibleConcepts = [];
        const finalColumns = visibleColumns.slice(1, visibleColumns.length);
        this.displayedConcepts = this.concepts;
        this.concepts.forEach(item => {
            // Activation Profiles on Concept page will only have length of 1, so we need to allow the concept to be added to displayedConcepts
            if (finalColumns.length === 1 && item.position) {
                visibleConcepts.push(item);
            } else {
                // if there is more than 1 concept and finalColumns is greater than 1, process the data as usual.
                if (finalColumns.includes(this.concepts.indexOf(item) + 1)) {
                    visibleConcepts.push(item);
                }
            }
        });
        this.displayedConcepts = visibleConcepts;
    }

    /**
     * Triggers mixpanel event on clicking 'Next' button in table
     *
     */
    nextPage(): void {
        this.mixpanelService.track(MixpanelLabel.activation, MixpanelEvent.nextConcept);
    }

    /**
     * Triggers mixpanel event on clicking 'Previous' button in table
     *
     */
    previousPage(): void {
        this.mixpanelService.track(MixpanelLabel.activation, MixpanelEvent.previousConcept);
    }
}
