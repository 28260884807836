<!--<div cdkDropList (cdkDropListDropped)="dropAnswer($event)">
    <div class="item-row-dragndrop" *ngFor="let answer of surveyQuestionConfiguration.answers" cdkDrag>
        <div class="cdk-custom-placeholder" *cdkDragPlaceholder></div>
        <div class="item-row">
            <button mat-icon-button cdkDragHandle class="drag-n-drop-button">
                <span class="sif sif-drag-handler sif-12 sif-gray"></span>
            </button>
            <input matInput class="name-input" (change)="displayNameChange($event, answer)" [value]="answer.editedAnswerText" matTooltipPosition="above" [matTooltip]="answer.editedAnswerText"/>
            <button mat-icon-button class="visibility-button text-radioBtn-visbility" (click)="toggleVisibility(answer)">
                <span class="sif sif-gray cursor-pointer" [ngClass]="answer.selected? 'sif-eye-enabled sif-12' : 'sif-eye-disabled sif-15'"></span>
            </button>
        </div>
    </div>
</div>-->
<div class="item-row-dragndrop" *ngFor="let answer of surveyQuestionConfiguration.answers">
    <div class="item-row padding-left-2">
        <input matInput class="name-input" (change)="displayNameChange($event, answer)" [value]="answer.editedAnswerText" matTooltipPosition="above" [matTooltip]="answer.editedAnswerText"/>
        <button mat-icon-button class="visibility-button text-radioBtn-visbility" (click)="toggleVisibility(answer)">
            <span class="sif sif-gray cursor-pointer" [ngClass]="answer.selected? 'sif-eye-enabled sif-12' : 'sif-eye-disabled sif-15'"></span>
        </button>
    </div>
</div>

